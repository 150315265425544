import { Plugin } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";
import type { DOMOutputSpec, MarkSpec, Node as ProsemirrorNode } from "prosemirror-model";
import { VARIABLE_REGEXP } from "../../../constants/regexps";
import "./variable.scss";

const variableMark: MarkSpec = {
  attrs: {},
  /**
   * TODO: if we need to detect variables from the DOM, we can use the following code
   *       but it requires additional investigation to fix different issues
   *       with cursor position
   * parseDOM: [
   *   { tag: "span.l-variable" }
   * ],
   */

  toDOM(): DOMOutputSpec {
    return ["span", { class: "l-variable" }, 0];
  },
};

function highlightVariables(doc: ProsemirrorNode): DecorationSet {
  const variables: Decoration[] = [];

  doc.descendants((node: ProsemirrorNode, pos: number) => {
    if (node.isText) {
      let match: RegExpExecArray | null = VARIABLE_REGEXP.exec(node.text!);

      while (match !== null) {
        const start = match.index;
        const end = start + match[0].length;

        // Check if the node is already wrapped with the variable class
        const parentNode = doc.nodeAt(pos);

        if (parentNode && parentNode.marks.some((mark) => mark.type.name === "highlight")) {
          match = VARIABLE_REGEXP.exec(node.text!);
          continue;
        }

        variables.push(Decoration.inline(pos + start, pos + end, { class: "l-variable" }));

        match = VARIABLE_REGEXP.exec(node.text!);
      }
    }
  });

  return DecorationSet.create(doc, variables);
}

function variablesHighlighterPlugin() {
  return new Plugin({
    state: {
      init(_, { doc }) {
        return DecorationSet.create(doc, []);
      },

      apply(tr, old, state) {
        if (tr.docChanged) {
          return highlightVariables(state.doc);
        }

        return old;
      },
    },
    props: {
      decorations(state) {
        return highlightVariables(state.doc);
      },
    },
  });
}

export { variablesHighlighterPlugin, variableMark };
