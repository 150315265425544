const config = {
  env: "production",
  host: {
    url: "https://app.lisapet.ai/",
  },
  firebase: {
    apiKey: "AIzaSyBMVBPp27kohlEc_iVFWX9Pu1rwyYnbgJI",
    authDomain: "app.lisapet.ai",
    projectId: "lisapet-ai",
    storageBucket: "lisapet-ai.appspot.com",
    messagingSenderId: "35808038077",
    appId: "1:35808038077:web:e06dd20c93454cee67bbd4",
  },
};

export default config;
