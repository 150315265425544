import type { ComponentType } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dialogsRegistry = new Map<string, ComponentType<any>>();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const register = (dialogId: string, Component: ComponentType<any>) => {
  if (dialogsRegistry.has(dialogId)) {
    throw new Error(`Dialog ${dialogId} already exists`);
  }

  dialogsRegistry.set(dialogId, Component);
};

export { dialogsRegistry, register };
