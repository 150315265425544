import React from "react";
import { Accordion, Box, Text, Stack } from "@gusarov-studio/rubik-ui";
import { NavigationActionList } from "../NavigationActionList";
import { NavigationAccordionTrigger } from "../NavigationAccordionTrigger";
import { noop } from "../../../../common/utils/noop";
import type { ItemsGroup, ListItem } from "../../types";

type NavigationGroupProps = React.PropsWithChildren<{
  onHeaderClick?: (item: ItemsGroup) => void;
  onItemClick: (event: React.MouseEvent, item: ListItem) => void;
  onItemsGroupSelect?: (eventKey: string[], e?: React.SyntheticEvent<unknown>) => void;
  onItemDoubleClick: (event: React.MouseEvent, item: ListItem) => void;
  selectedItems: ListItem[];
  title: string;
  titleGlyph: string;
  sublistGlyph: string;
  items: (ItemsGroup | ListItem)[];
}>;

const isNavigationGroup = (items: (ItemsGroup | ListItem)[]): items is ItemsGroup[] => {
  if (items.length === 0) {
    return true;
  }

  const [item] = items;

  return (item as ItemsGroup).items !== undefined;
};

function NavigationGroup(props: NavigationGroupProps) {
  const {
    onHeaderClick = noop,
    onItemsGroupSelect = noop,
    onItemDoubleClick,
    onItemClick,
    selectedItems,
    title,
    titleGlyph,
    sublistGlyph,
    items,
  } = props;

  const isGroup = isNavigationGroup(items);

  return (
    <Box paddingLeft="spacing-md">
      <Accordion.Root
        type="multiple"
        defaultValue={[title]}
      >
        <Accordion.AccordionItem value={title}>
          <NavigationAccordionTrigger
            chevronAppearance={items.length ? "right" : "none"}
            glyph={titleGlyph}
            appearance="quaternary"
          >
            <Text
              size="regular-sm"
              truncate
              appearance="inherit"
            >
              {title}
            </Text>
          </NavigationAccordionTrigger>

          {items.length > 0 && (
            <Accordion.AccordionContent>
              <Box paddingLeft="spacing-md">
                {isGroup && (
                  <Accordion.Root
                    type={"multiple"}
                    onValueChange={onItemsGroupSelect}
                  >
                    {items.map((item) => (
                      <Stack
                        gap="sm"
                        key={item.id}
                      >
                        <Accordion.AccordionItem value={item.id}>
                          <NavigationAccordionTrigger
                            chevronAppearance="left"
                            glyph={sublistGlyph}
                            onClick={() => onHeaderClick(item)}
                          >
                            <Text
                              size="regular-sm"
                              truncate
                              appearance="inherit"
                            >
                              {item.title}
                            </Text>
                          </NavigationAccordionTrigger>

                          {item.items && item.items.length > 0 && (
                            <Accordion.AccordionContent>
                              <Box paddingLeft="spacing-2xl">
                                <NavigationActionList
                                  items={item.items}
                                  onClick={onItemClick}
                                  onDoubleClick={onItemDoubleClick}
                                  selectedItems={selectedItems}
                                />
                              </Box>
                            </Accordion.AccordionContent>
                          )}
                        </Accordion.AccordionItem>
                      </Stack>
                    ))}
                  </Accordion.Root>
                )}

                {!isGroup && (
                  <NavigationActionList
                    items={items as ListItem[]}
                    onClick={onItemClick}
                    onDoubleClick={onItemDoubleClick}
                    selectedItems={selectedItems}
                  />
                )}
              </Box>
            </Accordion.AccordionContent>
          )}
        </Accordion.AccordionItem>
      </Accordion.Root>
    </Box>
  );
}

const Memorized = React.memo(NavigationGroup);

export { Memorized as NavigationGroup };
