// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l-profile--w03Po{display:flex;align-items:center;gap:12px;border-radius:6px;background-color:var(--bg-secondary);padding:16px}.l-profile-avatar--HnOf7{display:flex;flex-shrink:0;justify-content:center;align-items:center;overflow:hidden;border-radius:50%;width:40px;height:40px;background-color:var(--bg-primary)}.l-profile-info--wskw_{display:flex;flex-direction:column;overflow:hidden}`, "",{"version":3,"sources":["webpack://./src/modules/navigation/components/Profile/Profile.module.scss"],"names":[],"mappings":"AAAA,kBACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,iBAAA,CACA,oCAAA,CACA,YAAA,CAGF,yBACE,YAAA,CACA,aAAA,CACA,sBAAA,CACA,kBAAA,CACA,eAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CACA,kCAAA,CAGF,uBACE,YAAA,CACA,qBAAA,CACA,eAAA","sourcesContent":[".l-profile {\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  border-radius: 6px;\n  background-color: var(--bg-secondary);\n  padding: 16px;\n}\n\n.l-profile-avatar {\n  display: flex;\n  flex-shrink: 0;\n  justify-content: center;\n  align-items: center;\n  overflow: hidden;\n  border-radius: 50%;\n  width: 40px;\n  height: 40px;\n  background-color: var(--bg-primary);\n}\n\n.l-profile-info {\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"l-profile": `l-profile--w03Po`,
	"lProfile": `l-profile--w03Po`,
	"l-profile-avatar": `l-profile-avatar--HnOf7`,
	"lProfileAvatar": `l-profile-avatar--HnOf7`,
	"l-profile-info": `l-profile-info--wskw_`,
	"lProfileInfo": `l-profile-info--wskw_`
};
export default ___CSS_LOADER_EXPORT___;
