// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l-navigation--dtLOW{display:flex;flex-direction:column;min-height:100%;max-width:var(--l-opened-navigation-size);min-width:var(--l-opened-navigation-size);background-color:var(--bg-primary);color:var(--text-primary)}`, "",{"version":3,"sources":["webpack://./src/modules/navigation/components/Navigation/Navigation.module.scss"],"names":[],"mappings":"AAAA,qBACE,YAAA,CACA,qBAAA,CACA,eAAA,CACA,yCAAA,CACA,yCAAA,CACA,kCAAA,CACA,yBAAA","sourcesContent":[".l-navigation {\n  display: flex;\n  flex-direction: column;\n  min-height: 100%;\n  max-width: var(--l-opened-navigation-size);\n  min-width: var(--l-opened-navigation-size);\n  background-color: var(--bg-primary);\n  color: var(--text-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"l-navigation": `l-navigation--dtLOW`,
	"lNavigation": `l-navigation--dtLOW`
};
export default ___CSS_LOADER_EXPORT___;
