import { createSelector } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { PromptGroup } from "../../../types/PromptGroup";
import __ls from "../../../__localStore";

const promptGroupsApi = createApi({
  reducerPath: "api.PromptGroup",
  baseQuery: fetchBaseQuery({ baseUrl: "/static/mock-api" }),
  endpoints: (build) => ({
    getPromptGroups: build.query<PromptGroup[], void>({
      query: () => "/prompt-groups.json?getPromptGroups",
      // @ts-expect-error test-purpose
      transformResponse: __ls.createOnGet("__promptGroups"),
    }),
  }),
});

const getPromptGroupsResult = promptGroupsApi.endpoints.getPromptGroups.select();
const getPromptGroups = createSelector(
  getPromptGroupsResult,
  (promptGroupsResult) => promptGroupsResult ?? [],
);

const selectors = {
  getPromptGroups,
};

const { useGetPromptGroupsQuery } = promptGroupsApi;

export { promptGroupsApi, selectors, useGetPromptGroupsQuery };
