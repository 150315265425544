import type { PayloadAction } from "@reduxjs/toolkit";
import { createAppSlice } from "../../../store/createAppSlice";
import type { Project } from "../../../types/Project";

interface ProjectsState {
  activeProjectId: Project["id"] | null;
}

const initialState: ProjectsState = {
  activeProjectId: null,
};

const projectsSlice = createAppSlice({
  name: "projects",
  initialState,

  reducers: {
    setActiveProject: (state, action: PayloadAction<Project["id"]>) => {
      state.activeProjectId = action.payload;
    },
  },

  selectors: {
    getActiveProjectId: (state) => state.activeProjectId,
  },
});

const { actions, selectors } = projectsSlice;

export { projectsSlice, actions, selectors };
