import React, { useEffect, useMemo } from "react";

const CALENDLY_URL =
  "https://calendly.com/lisapet-ai/30-minutes-meeting?hide_gdpr_banner=1&hide_event_type_details=1&primary_color=0D0C22";

interface CalendlyWidgetProps {
  name?: string | null;
  email: string;
}

function CalendlyWidget(props: CalendlyWidgetProps) {
  const { name, email } = props;

  const queryParameters = useMemo(() => {
    const result = `email=${encodeURIComponent(email)}`;

    if (name) {
      return `${result}&name=${encodeURIComponent(name)}`;
    }

    return result;
  }, [name, email]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      id="custom-calendly-widget"
      className="calendly-inline-widget"
      data-url={`${CALENDLY_URL}&${queryParameters}`}
      style={{
        height: "630px",
        minWidth: "320px",
      }}
    />
  );
}

export { CalendlyWidget };
