import React from "react";
import { Box, Stack, Text, InputSlider } from "@gusarov-studio/rubik-ui";
import { Controller, type Control } from "react-hook-form";
import styles from "./ModelSettings.module.scss";

interface ModelSettingsProps {
  control: Control<{
    messages: {
      type: string;
      value: string;
      id: string;
    }[];
    variables: {
      name: string;
      value: string;
    }[];
    temperature: number;
    maxLength: number;
    topP: number;
    presencePenalty: number;
    frequencyPenalty: number;
  }>;
}

function ModelSettings(props: ModelSettingsProps) {
  const { control } = props;

  return (
    <div>
      <Box padding="spacing-xl">
        <Stack gap="3xl">
          <Stack gap="lg">
            <Controller
              control={control}
              name="temperature"
              render={({ field }) => (
                <>
                  <Text>
                    Temperature
                    <Text className={styles["lModelSettings__inputValue"]}>{field.value}</Text>
                  </Text>

                  <InputSlider
                    onValueChange={([value]) => {
                      field.onChange(value);
                    }}
                    name="temperature"
                    min={0}
                    max={1}
                    step={0.1}
                    defaultValue={[field.value]}
                  />
                </>
              )}
            />
          </Stack>

          <Stack gap="md">
            <Controller
              control={control}
              name="maxLength"
              render={({ field }) => (
                <>
                  <Text>
                    Maximum length
                    <Text className={styles["lModelSettings__inputValue"]}>{field.value}</Text>
                  </Text>

                  <InputSlider
                    onValueChange={([value]) => {
                      field.onChange(value);
                    }}
                    name="temperature"
                    min={0}
                    max={1000}
                    step={1}
                    defaultValue={[field.value]}
                  />
                </>
              )}
            />
          </Stack>

          <Stack gap="md">
            <Controller
              control={control}
              name="topP"
              render={({ field }) => (
                <>
                  <Text>
                    Top P<Text className={styles["lModelSettings__inputValue"]}>{field.value}</Text>
                  </Text>

                  <InputSlider
                    onValueChange={([value]) => {
                      field.onChange(value);
                    }}
                    name="topP"
                    min={0}
                    max={1}
                    step={0.1}
                    defaultValue={[field.value]}
                  />
                </>
              )}
            />
          </Stack>

          <Stack gap="md">
            <Controller
              control={control}
              name="frequencyPenalty"
              render={({ field }) => (
                <>
                  <Text>
                    Frequency penalty
                    <Text className={styles["lModelSettings__inputValue"]}>{field.value}</Text>
                  </Text>

                  <InputSlider
                    onValueChange={([value]) => {
                      field.onChange(value);
                    }}
                    name="topP"
                    min={0}
                    max={1}
                    step={0.1}
                    defaultValue={[field.value]}
                  />
                </>
              )}
            />
          </Stack>

          <Stack gap="md">
            <Controller
              control={control}
              name="presencePenalty"
              render={({ field }) => (
                <>
                  <Text>
                    Presence penalty
                    <Text className={styles["lModelSettings__inputValue"]}>{field.value}</Text>
                  </Text>

                  <InputSlider
                    onValueChange={([value]) => {
                      field.onChange(value);
                    }}
                    name="topP"
                    min={0}
                    max={1}
                    step={0.1}
                    defaultValue={[field.value]}
                  />
                </>
              )}
            />
          </Stack>
        </Stack>
      </Box>
    </div>
  );
}

export { ModelSettings };
