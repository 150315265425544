// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l-file-selector--snKBM{display:flex;gap:16px;flex-wrap:wrap;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/modules/editor/components/FileSelector/FileSelector.module.scss"],"names":[],"mappings":"AAAA,wBACE,YAAA,CACA,QAAA,CACA,cAAA,CACA,sBAAA","sourcesContent":[".l-file-selector {\n  display: flex;\n  gap: 16px;\n  flex-wrap: wrap;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"l-file-selector": `l-file-selector--snKBM`,
	"lFileSelector": `l-file-selector--snKBM`
};
export default ___CSS_LOADER_EXPORT___;
