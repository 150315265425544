import React from "react";
import { Box, Stack, InputText, Text, Button } from "@gusarov-studio/rubik-ui";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@lisapet/ui";
import styles from "./PromptConfiguration.module.scss";
import type { NewPrompt, Prompt } from "../../../../types/Prompt";
import type { Control } from "react-hook-form";
import { useForm } from "react-hook-form";
import type { PromptGroup } from "../../../../types/PromptGroup";
import { Variables } from "../Variables";
import type { Variable, PlaygroundForm } from "../../types";
import { ModelSettings } from "../ModelSettings";

interface AiModel {
  title: string;
  value: string;
}

interface PromptConfigurationProps {
  prompt: Prompt | NewPrompt;
  onSubmit: (data: Prompt | NewPrompt) => void;
  onDelete?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  models: AiModel[];
  promptGroups?: PromptGroup[];
  variables?: Variable[];
  control: Control<PlaygroundForm>;
}

function PromptConfiguration(props: PromptConfigurationProps) {
  const {
    prompt,
    disabled,
    onSubmit,
    onDelete,
    models,
    promptGroups,
    variables = [],
    control,
  } = props;

  const {
    register,
    formState: { errors },
    clearErrors,
    handleSubmit,
  } = useForm<Prompt | NewPrompt>({
    reValidateMode: "onBlur",
    defaultValues: prompt,
  });

  return (
    <div className={styles["lPromptConfiguration"]}>
      <Tabs defaultValue="variables">
        <TabsList>
          <TabsTrigger value="variables">Variables</TabsTrigger>
          <TabsTrigger value="modelSettings2">Model settings 2</TabsTrigger>
          <TabsTrigger value="modelSettings">Model settings</TabsTrigger>
        </TabsList>

        <TabsContent value="variables">
          <Variables
            variables={variables}
            control={control}
          />
        </TabsContent>

        <TabsContent value="modelSettings">
          <ModelSettings control={control} />
        </TabsContent>

        <TabsContent value="modelSettings2">
          <Box padding="spacing-xl">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack gap="sm">
                <Stack gap="sm">
                  <Text
                    htmlFor="name"
                    as="label"
                  >
                    Name
                  </Text>

                  <InputText
                    id="name"
                    {...register("name", {
                      required: "Required",
                    })}
                    onInput={() => {
                      if (errors.name) clearErrors("name");
                    }}
                  />

                  {errors && errors.name && <Text appearance="error">{errors.name.message}</Text>}
                </Stack>

                <Stack gap="sm">
                  <Text
                    htmlFor="llvm"
                    as="label"
                  >
                    llvm
                  </Text>

                  <select
                    id="llvm"
                    {...register("llvm")}
                  >
                    {models.map(({ value, title }) => (
                      <option
                        key={value}
                        value={value}
                      >
                        {title}
                      </option>
                    ))}
                  </select>

                  {errors && errors.llvm && (
                    <Text appearance="error">{errors.llvm.toString()}</Text>
                  )}
                </Stack>

                {promptGroups && (
                  <Stack gap="sm">
                    <Text
                      htmlFor="promptGroupId"
                      as="label"
                    >
                      promptGroupId
                    </Text>

                    <select
                      id="promptGroupId"
                      {...register("promptGroupId", {
                        required: "Required",
                      })}
                    >
                      {promptGroups.map((option) => (
                        <option
                          key={option.id}
                          value={option.id}
                        >
                          {option.title}
                        </option>
                      ))}
                    </select>
                  </Stack>
                )}

                <Button
                  type="submit"
                  disabled={disabled}
                >
                  Save
                </Button>

                {onDelete && (
                  <Button
                    variant="outline-primary"
                    disabled={disabled}
                    onClick={onDelete}
                  >
                    DELETE
                  </Button>
                )}
              </Stack>
            </form>
          </Box>
        </TabsContent>
      </Tabs>
    </div>
  );
}

export { PromptConfiguration };
