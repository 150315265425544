import React from "react";
import { Accordion, Icon, type IconGlyph } from "@gusarov-studio/rubik-ui";
import styles from "./NavigationAccordionTrigger.module.scss";
import clsx from "clsx";

interface NavigationAccordionTriggerProps extends React.PropsWithChildren {
  onClick?: React.MouseEventHandler;
  chevronAppearance?: "none" | "left" | "right";
  glyph: string;
  appearance?: "default" | "quaternary";
}

function NavigationAccordionTrigger(props: NavigationAccordionTriggerProps) {
  const { chevronAppearance = "none", appearance = "default", children, glyph, onClick } = props;

  return (
    <Accordion.AccordionTrigger
      className={clsx(styles["lNavigationAccordionTrigger"], {
        [styles["lNavigationAccordionTriggerAppearanceQuaternary"]]: appearance === "quaternary",
        [styles["lNavigationAccordionTriggerAppearanceDefault"]]: appearance === "default",
      })}
      onClick={onClick}
    >
      {chevronAppearance === "left" && (
        <Accordion.AccordionChevron
          position="left"
          className={styles["lNavigationAccordionTrigger__icon"]}
        />
      )}

      <Icon
        glyph={glyph as IconGlyph}
        size={18}
        appearance="inherit"
        className={styles["lNavigationAccordionTrigger__icon"]}
      />

      {children}

      {chevronAppearance === "right" && (
        <Accordion.AccordionChevron
          position="right"
          className={styles["lNavigationAccordionTrigger__icon"]}
        />
      )}
    </Accordion.AccordionTrigger>
  );
}

export { NavigationAccordionTrigger };
