import type { Project } from "../../types/Project";

function create(
  projectData: Omit<Project, "id">,
  options: { timeout?: number } = {},
): Promise<Project> {
  return new Promise((res) => {
    setTimeout(() => {
      const projects = localStorage.getItem("__projects");
      const data = projects ? (JSON.parse(projects) as Project[]) : [];

      const item = {
        ...projectData,
        id: `project-${Date.now()}-${Math.random()}`,
      };

      data.push(item);
      localStorage.setItem("__projects", JSON.stringify(data));

      res(item);
    }, options.timeout || 1000);
  });
}

export default create;
