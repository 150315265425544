import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { Participant } from "../types";

const onboardingApi = createApi({
  reducerPath: "api.onboarding",
  baseQuery: fetchBaseQuery({ baseUrl: "https://hook.eu2.make.com/" }),
  endpoints: (build) => ({
    addParticipant: build.mutation<void, Participant>({
      query: (data: Participant) => ({
        url: "/8q0d6jj81wpr5regmw4h9pcgg8wjqspv",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

const { useAddParticipantMutation } = onboardingApi;

export { onboardingApi, useAddParticipantMutation };
