import get from "lodash.get";
import Joi from "joi";
import config from "config.yml";

const schema = Joi.object({
  env: Joi.string().valid("production", "staging", "development", "local").required(),
  firebase: Joi.object({
    apiKey: Joi.string().required(),
    authDomain: Joi.string().required(),
    projectId: Joi.string().required(),
    storageBucket: Joi.string().required(),
    messagingSenderId: Joi.string().required(),
    appId: Joi.string().required(),
  }).required(),
  functions: Joi.object({
    chatCompletion: Joi.string().required(),
  }).required(),
  emulators: Joi.object({
    auth: Joi.string().required(),
    dataconnect: Joi.object({
      host: Joi.string().required(),
      port: Joi.number().required(),
    }).required(),
  }).optional(),
});

const inferredConfig = Joi.attempt(config, schema);

export default {
  get: (path: string, defaults?: string) => get(inferredConfig, path, defaults),
};
