import React from "react";
import { Box, Text } from "@gusarov-studio/rubik-ui";
import { CenteredColumnLayout } from "../../../common/components/Layout";
import { useTranslator } from "../../localization";
import image from "../assets/image_6c.jpg";

function SuccessAuthScreen() {
  const i18n = useTranslator();

  return (
    <CenteredColumnLayout
      termsOfUse={{
        title: i18n.t("common.termsOfUse"),
        href: "#",
      }}
      privacyPolicy={{
        title: i18n.t("common.privacyPolicy"),
        href: "#",
      }}
      copyright="© 2024 Lisapet.AI"
      textAlign="center"
    >
      <img
        style={{ margin: "0 auto" }}
        src={image}
        width="272"
        height="272"
        alt="img"
      />
      <Box
        marginTop="spacing-4xl"
        marginBottom="spacing-xl"
      >
        <Text
          style={{ textAlign: "center" }}
          block
          size="display-md"
          weight="semibold"
        >
          {i18n.t("auth.youAreIn")}
        </Text>
      </Box>

      <p>{i18n.t("auth.thanksForRegistration")}</p>
    </CenteredColumnLayout>
  );
}

export { SuccessAuthScreen };
