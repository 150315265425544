import React from "react";
import styles from "./DialogLayout.module.scss";

function DialogLayout(props: React.PropsWithChildren) {
  const { children } = props;

  return <div className={styles["lDialogLayout"]}>{children}</div>;
}

export { DialogLayout };
