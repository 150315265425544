function get<T>(key: string, data: T[]): T[] {
  const localStorageData = localStorage.getItem(key);

  try {
    if (!localStorageData || !data) throw new Error("oops");

    return JSON.parse(localStorageData!) as T[];
  } catch {
    localStorage.setItem(key, JSON.stringify(data));

    return data;
  }
}

function getByQuery<T>(key: string, data: T[], arg, searchFn, isSingle): T[] {
  const localStorageData = localStorage.getItem(key);
  let list = [];
  try {
    if (!localStorageData || !data) throw new Error("oops");

    list = JSON.parse(localStorageData!) as T[];
  } catch {
    localStorage.setItem(key, JSON.stringify(data));

    list = data;
  }

  if (isSingle) {
    return list.find((item) => searchFn(item, arg)) || null;
  }

  return list.filter((item) => searchFn(item, arg));
}

function add<T>(key: string, data: T): T {
  const localStorageData = localStorage.getItem(key);

  try {
    if (!data) throw new Error("oops");

    const list = JSON.parse(localStorageData!) as T[];

    list.push(data);

    localStorage.setItem(key, JSON.stringify(list));
    return data;
  } catch {
    console.warn("[Incorrect work with mock data]: ", data);

    return data;
  }
}

function update<T, F extends (item: T) => boolean>(key: string, data: T, searchFn: F): T {
  const localStorageData = localStorage.getItem(key);

  try {
    if (!data) throw new Error("oops");

    const list = JSON.parse(localStorageData!) as T[];

    const targetItem = list.find(searchFn);

    Object.entries(data).forEach(([key, value]) => {
      targetItem[key] = value;
    });

    localStorage.setItem(key, JSON.stringify(list));

    return targetItem;
  } catch {
    console.warn("[Incorrect work with mock data]: ", data);

    return data;
  }
}

function del<T, F extends (item: T) => boolean>(key: string, data: T, searchFn: F): T {
  const localStorageData = localStorage.getItem(key);

  try {
    if (!data) throw new Error("oops");

    const list = JSON.parse(localStorageData!) as T[];
    console.log(list, list.length);
    const newList = list.filter((listItem) => !searchFn(listItem));
    console.log("newlistl", newList.length);

    localStorage.setItem(key, JSON.stringify(newList));

    return data;
  } catch {
    console.warn("[Incorrect work with mock data]: ", data);

    return data;
  }
}

function createOnGet(key: string) {
  return <A, B, C>(baseQueryReturnValue: A, meta: B, arg: C) => {
    console.log("GET ", key, baseQueryReturnValue);
    return get(`${key}`, baseQueryReturnValue);
  };
}

function createOnGetQuery(key: string, searchFn, isSingleValue = false) {
  return <A, B, C>(baseQueryReturnValue: A, meta: B, arg: C) => {
    console.log("GET ", key, baseQueryReturnValue);
    return getByQuery(`${key}`, baseQueryReturnValue, arg, searchFn, isSingleValue);
  };
}

function createOnAdd(key: string) {
  return <A, B, C>(baseQueryReturnValue: A, meta: B, arg: C) => {
    console.log("POST ", key, arg);
    return add(`${key}`, {
      ...arg,
      id: `${key}-${Date.now()}`,
    });
  };
}

function createOnDelete(key: string, cb) {
  return <A, B, C>(baseQueryReturnValue: A, meta: B, arg: C) => {
    console.log("DELETE ", key, arg);
    return del(`${key}`, arg, (item) => cb(item, arg));
  };
}

function createOnUpdate(key: string, cb) {
  console.log(key, "UP");
  return <A, B, C>(baseQueryReturnValue: A, meta: B, arg: C) => {
    console.log(key, "U111212PU111212PU111212PU111212P");
    console.log("UPDATE ", key, arg);
    return update(`${key}`, arg, (item) => cb(item, arg));
  };
}

export default {
  createOnAdd,
  createOnGet,
  createOnGetQuery,
  createOnDelete,
  createOnUpdate,
};
