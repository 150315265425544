import React, { useMemo } from "react";
import { Profile } from "../components/Profile";
import { useAuth } from "../../auth";

function getLogoFallback(name: string | null, email: string) {
  if (name) {
    const words = name.split(" ");

    if (words.length > 1) {
      return `${words[0][0]}${words[1][0]}`.toUpperCase();
    }

    if (words[0].length > 1) {
      return words[0].slice(0, 2).toUpperCase();
    }

    return `${words[0][0]}`.toUpperCase();
  }

  if (email.indexOf("@") === 1) {
    return email[0].toUpperCase();
  }

  return email.slice(0, 2).toUpperCase();
}

function ProfileContainer() {
  const { user } = useAuth();
  const { displayName, email, photoURL } = user!;
  const logoFallback = useMemo(() => getLogoFallback(displayName, email!), [displayName, email]);

  return (
    // TODO: replace div with styles by Box
    <div style={{ padding: "20px 16px" }}>
      <Profile
        name={displayName}
        email={email}
        logoSrc={photoURL}
        logoFallback={logoFallback}
        altText={logoFallback}
      />
    </div>
  );
}

export { ProfileContainer };
