// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l-prompt-messages--L0oOC{display:flex;flex-direction:column;padding:32px 0;gap:12px;width:100%;max-width:922px;margin:0 auto}.l-prompt-messages__row--Lg_xD{display:flex;align-items:stretch}.l-prompt-messages__author--TEhMs{flex:0 0 85px;padding-top:8px;display:flex;justify-content:flex-end;align-items:flex-start}.l-prompt-messages__main--V0yEZ{width:100%;flex:1 1 100%;margin-left:16px;margin-right:8px}.l-prompt-messages__message--d4LQv{border-radius:6px;padding:12px 16px;background-color:var(--bg-primary)}.l-prompt-messages__interaction--EIeq5{flex:0 0 48px;display:flex;align-items:flex-start;padding-top:8px}`, "",{"version":3,"sources":["webpack://./src/modules/editor/components/PromptMessages/PromptMessages.module.scss"],"names":[],"mappings":"AAAA,0BACE,YAAA,CACA,qBAAA,CACA,cAAA,CACA,QAAA,CACA,UAAA,CACA,eAAA,CACA,aAAA,CAEA,+BACE,YAAA,CACA,mBAAA,CAGF,kCACE,aAAA,CACA,eAAA,CACA,YAAA,CACA,wBAAA,CACA,sBAAA,CAGF,gCACE,UAAA,CACA,aAAA,CACA,gBAAA,CACA,gBAAA,CAGF,mCACE,iBAAA,CACA,iBAAA,CACA,kCAAA,CAGF,uCACE,aAAA,CACA,YAAA,CACA,sBAAA,CACA,eAAA","sourcesContent":[".l-prompt-messages {\n  display: flex;\n  flex-direction: column;\n  padding: 32px 0;\n  gap: 12px;\n  width: 100%;\n  max-width: 922px;\n  margin: 0 auto;\n\n  &__row {\n    display: flex;\n    align-items: stretch;\n  }\n\n  &__author {\n    flex: 0 0 85px;\n    padding-top: 8px;\n    display: flex;\n    justify-content: flex-end;\n    align-items: flex-start;\n  }\n\n  &__main {\n    width: 100%;\n    flex: 1 1 100%;\n    margin-left: 16px;\n    margin-right: 8px;\n  }\n\n  &__message {\n    border-radius: 6px;\n    padding: 12px 16px;\n    background-color: var(--bg-primary);\n  }\n\n  &__interaction {\n    flex: 0 0 48px;\n    display: flex;\n    align-items: flex-start;\n    padding-top: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"l-prompt-messages": `l-prompt-messages--L0oOC`,
	"lPromptMessages": `l-prompt-messages--L0oOC`,
	"l-prompt-messages__row": `l-prompt-messages__row--Lg_xD`,
	"lPromptMessages__row": `l-prompt-messages__row--Lg_xD`,
	"l-prompt-messages__author": `l-prompt-messages__author--TEhMs`,
	"lPromptMessages__author": `l-prompt-messages__author--TEhMs`,
	"l-prompt-messages__main": `l-prompt-messages__main--V0yEZ`,
	"lPromptMessages__main": `l-prompt-messages__main--V0yEZ`,
	"l-prompt-messages__message": `l-prompt-messages__message--d4LQv`,
	"lPromptMessages__message": `l-prompt-messages__message--d4LQv`,
	"l-prompt-messages__interaction": `l-prompt-messages__interaction--EIeq5`,
	"lPromptMessages__interaction": `l-prompt-messages__interaction--EIeq5`
};
export default ___CSS_LOADER_EXPORT___;
