import React from "react";
import type { PlaygroundForm } from "../../types";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@lisapet/ui";

interface TestDialogProps {
  onClose: () => void;
  data: PlaygroundForm;
}

function TestDialog(props: TestDialogProps) {
  const { onClose, data = { hellow: "world" } } = props;

  return (
    <Dialog
      open={true}
      onOpenChange={onClose}
    >
      <DialogContent style={{ backgroundColor: "#F9FAFB" }}>
        <DialogHeader>
          <DialogTitle>Form data</DialogTitle>
          <DialogDescription>
            <span style={{ display: "block", whiteSpace: "pre" }}>
              {JSON.stringify(data, null, 2)}
            </span>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}

export { TestDialog };
