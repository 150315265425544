import type { ValueOf } from "../../../types/utility/ValueOf";

const logTypes = {
  INFO: "info",
  RESULT: "result",
} as const;

type LogType = ValueOf<typeof logTypes>;

export { logTypes, type LogType };
