import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import "./common/styles/index.scss";
import { store } from "./store";
import { router } from "./router/router";
import __prepareLocalStorage from "./__prepareLocalStorage";
import "./firebase";
import { localization, LocalizationProvider } from "./modules/localization";
import { ThemesProvider } from "@gusarov-studio/rubik-ui";
import createChatCompletion from "./clients/playground/createChatCompetion";

// @ts-expect-error temporary code
window.__api = { createChatCompletion };

// @ts-expect-error temporary code
window.__prepareLocalStorage = __prepareLocalStorage; //

const domNode = document.getElementById("lisapet") as HTMLElement;
const root = createRoot(domNode);

// @ts-expect-error temporary code
window.__locale = (locale: string) => {
  store.dispatch(localization.actions.setLocale(locale));
};

// @ts-expect-error temporary code
window._gs = () => console.log(store.getState());

store.dispatch(localization.actions.setLocale(localization.locales.EN)).then(() => {
  root.render(
    <Provider store={store}>
      <ThemesProvider>
        <LocalizationProvider>
          <RouterProvider router={router} />
        </LocalizationProvider>
      </ThemesProvider>
    </Provider>,
  );
});
