import { getAuth } from "firebase/auth";
import { getSignupRedirectResults } from "../requests/getRedirectResult";

async function isUserAuthenticated(): Promise<boolean> {
  try {
    await getSignupRedirectResults();
  } catch (error) {
    console.error("Failed to get signup redirect results:", error);

    return false;
  }

  return new Promise((resolve) => {
    const auth = getAuth();

    auth.authStateReady().then(() => {
      if (auth.currentUser) {
        resolve(true);
      }

      resolve(false);
    });
  });
}

export { isUserAuthenticated };
