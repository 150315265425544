import {
  promptsApi,
  selectors,
  useGetPromptsQuery,
  useGetPromptsByGroupQuery,
  useAddPromptMutation,
  useDeletePromptMutation,
  useUpdatePromptMutation,
} from "./api/promptsApi";

const prompts = {
  selectors,
};

export {
  prompts,
  promptsApi,
  useGetPromptsQuery,
  useGetPromptsByGroupQuery,
  useAddPromptMutation,
  useDeletePromptMutation,
  useUpdatePromptMutation,
};
