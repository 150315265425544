import React from "react";
import styles from "./EditorLayout.module.scss";

function EditorLayout(props: React.PropsWithChildren) {
  const { children } = props;

  return <div className={styles["lEditorLayout"]}>{children}</div>;
}

export { EditorLayout };
