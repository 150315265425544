import React from "react";
import { Text, Button, Icon } from "@gusarov-studio/rubik-ui";
import styles from "./ExecutionLogs.module.scss";
import { ExecutionLog } from "./ExecutionLog";
import type { Log, ResponseLog } from "../../types";

interface ExecutionLogsProps {
  onResponseClick: (log: ResponseLog) => void;
  logs: Log[];
  title: string;
  onClose: React.MouseEventHandler;
  labels: {
    title: string;
    buttonText: string;
    cost: string;
    responseTime: string;
    tokens: string;
  };
}

function ExecutionLogs(props: ExecutionLogsProps) {
  const { logs, title, onClose, labels, onResponseClick } = props;

  return (
    <div className={styles["lExecutionLogs"]}>
      <div className={styles["lExecutionLogs__header"]}>
        <div className={styles["lExecutionLogs__headerTitle"]}>
          <Icon
            glyph="terminal"
            size="20"
            appearance="inherit"
            strokeWidth="1.5"
          />

          <Text
            weight="semibold"
            size="regular-sm"
          >
            {title}
          </Text>
        </div>

        <Button
          variant="text-accent"
          onClick={onClose}
        >
          <Icon glyph="x" />
        </Button>
      </div>

      <div className={styles["lExecutionLogs__logs"]}>
        {logs.map((log, index) => (
          <ExecutionLog
            key={index}
            log={log}
            labels={labels}
            onResponseClick={() => onResponseClick(log as ResponseLog)}
          />
        ))}
      </div>
    </div>
  );
}

export { ExecutionLogs };
