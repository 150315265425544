import React from "react";
import styles from "./FileSelector.module.scss";

function FileSelector(props: React.PropsWithChildren) {
  const { children } = props;

  return <div className={styles["lFileSelector"]}>{children}</div>;
}

export { FileSelector };
