import toQueryString from "./helpers/toQueryString";

const routesMap = {
  home: {
    path: "/",
    url: () => "/",
  },
  getStarted: {
    path: "/get-started",
    url: () => "/get-started",
  },
  login: {
    path: "/login",
    url: (params = {}) => `/login${toQueryString(params)}`,
  },
  signup: {
    path: "/signup",
    url: (params = {}) => `/signup${toQueryString(params)}`,
  },
  logout: {
    path: "/logout",
    url: () => "/logout",
  },
  successAuth: {
    path: "/you-are-in",
    url: () => "/you-are-in",
  },
  termsOfUse: {
    path: "/terms-of-use",
    url: () => "/terms-of-use",
  },
  privacyPolicy: {
    path: "/privacy-policy",
    url: () => "/privacy-policy",
  },
  editor: {
    path: "/editor",
    url: ({ fileType, fileId, fileName }: { fileType: string; fileId: string; fileName: string }) =>
      `/editor/${fileType}/${fileId}/${encodeURI(fileName)}`,
  },
  editFile: {
    // nested `editor` route
    path: "/editor/edit/:fileType/:fileId/:fileName",
    url: ({ fileType, fileId, fileName }: { fileType: string; fileId: string; fileName: string }) =>
      `/editor/edit/${fileType}/${fileId}/${encodeURI(fileName)}`,
  },
  newFile: {
    path: "/editor/new",
    url: () => "/editor/new",
  },
  createFile: {
    path: "/editor/create/:fileType",
    url: ({ fileType }: { fileType: string }) => `/editor/create/${fileType}`,
  },
  onboardingForm: {
    path: "/onboarding/form",
    url: () => "/onboarding/form",
  },
  onboardingScheduling: {
    path: "/onboarding/scheduling",
    url: (params: { name?: string } = {}) => `/onboarding/scheduling${toQueryString(params)}`,
  },
};

export { routesMap };
