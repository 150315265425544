import React, { createContext, useEffect, useState, useMemo } from "react";
import i18n from "../helpers/i18n";

const LocalizationContext = createContext({
  t: i18n.t,
});

function LocalizationProvider(props: React.PropsWithChildren) {
  const { children } = props;
  const [version, setVersion] = useState(i18n.version);

  useEffect(() => {
    const unsubscribe = i18n.onChange(() => {
      setVersion(i18n.version);
    });

    return () => unsubscribe();
  }, [version]);

  const translator = useMemo<{ t: typeof i18n.t }>(
    () => ({
      t: (...args) => i18n.t(...args),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [version],
  );

  return <LocalizationContext.Provider value={translator}>{children}</LocalizationContext.Provider>;
}

export { LocalizationProvider, LocalizationContext };
