import type { Report } from "../../types/Report";

function create(
  reportData: Omit<Report, "id">,
  options: { timeout?: number } = {},
): Promise<Report> {
  return new Promise((res) => {
    setTimeout(() => {
      const reports = localStorage.getItem("__reports");
      const data = reports ? (JSON.parse(reports) as Report[]) : [];

      const item: Report = {
        ...reportData,
        id: `report-${Date.now()}-${Math.random()}`,
      };

      data.push(item);
      localStorage.setItem("__reports", JSON.stringify(data));

      res(item);
    }, options.timeout || 1000);
  });
}

export default create;
