import React, { useCallback, useEffect } from "react";
import styles from "./TextFragment.module.scss";

interface TextFragmentProps {
  text: string;
}

function TextFragment(props: TextFragmentProps) {
  const { text } = props;
  const containerRef = React.useRef<HTMLDivElement>(null);
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);
  const hiddenTextareaRef = React.useRef<HTMLTextAreaElement>(null);

  const adjustTextareaHeight = useCallback(() => {
    if (hiddenTextareaRef.current && textareaRef.current) {
      hiddenTextareaRef.current.value = text;
      const scrollHeight = hiddenTextareaRef.current.scrollHeight;
      textareaRef.current.style.height = `${scrollHeight}px`;
    }
  }, [text]);

  useEffect(() => {
    adjustTextareaHeight();
  }, [text, adjustTextareaHeight]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      adjustTextareaHeight();
    });

    const { current: textareaContainer } = containerRef;

    if (textareaContainer) {
      resizeObserver.observe(textareaContainer);
    }

    return () => {
      if (textareaContainer) {
        resizeObserver.unobserve(textareaContainer);
      }
    };
  }, [adjustTextareaHeight]);

  return (
    <div
      className={styles["lTextFragment"]}
      ref={containerRef}
    >
      <textarea
        className={styles["lTextFragment__textFragment"]}
        readOnly
        value={text}
        ref={textareaRef}
      />

      <textarea
        className={styles["lTextFragment__hiddenTextFragment"]}
        ref={hiddenTextareaRef}
        readOnly
      />
    </div>
  );
}

export { TextFragment };
