import React from "react";
import { useSelector } from "react-redux";
import { dialogsSlice } from "../slices/dialogsSlice";
import { DialogLayout } from "../components/DialogLayout";
import { dialogsRegistry } from "../dialogsRegistry";

function DialogsManagerContainer() {
  const { activeDialog, props: dialogProps } = useSelector(dialogsSlice.selectors.getDialogsState);

  if (!activeDialog || !dialogsRegistry.has(activeDialog)) return null;

  const DialogComponent = dialogsRegistry.get(activeDialog)!;

  return (
    <DialogLayout>
      <DialogComponent {...dialogProps} />
    </DialogLayout>
  );
}

export { DialogsManagerContainer };
