import React, { useContext } from "react";
import { ThemesContext } from "@gusarov-studio/rubik-ui";
import clsx from "clsx";
import styles from "./Navigation.module.scss";

function Navigation(props: React.PropsWithChildren) {
  const { children } = props;
  const themeContext = useContext(ThemesContext);

  return <nav className={clsx(styles["lNavigation"], themeContext.themes.dark)}>{children}</nav>;
}

export { Navigation };
