// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l-prompt-configuration--cQG2a{background-color:var(--bg-primary);height:100%;overflow-y:auto}`, "",{"version":3,"sources":["webpack://./src/modules/editor/components/PromptConfiguration/PromptConfiguration.module.scss"],"names":[],"mappings":"AAAA,+BACE,kCAAA,CACA,WAAA,CACA,eAAA","sourcesContent":[".l-prompt-configuration {\n  background-color: var(--bg-primary);\n  height: 100%;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"l-prompt-configuration": `l-prompt-configuration--cQG2a`,
	"lPromptConfiguration": `l-prompt-configuration--cQG2a`
};
export default ___CSS_LOADER_EXPORT___;
