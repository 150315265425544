import { createAppSlice } from "../../../store/createAppSlice";
import { type Status, status } from "../../../common/constants/status";
import config from "../constants/config";
import requests from "../requests";
import i18n from "../helpers/i18n";
import type { RootState } from "../../../store";

interface LocalizationState {
  currentLocale: string | null;
  status: Status;
  loadedLocales: string[];
}

const initialState: LocalizationState = {
  currentLocale: null,
  status: status.IDLE,
  loadedLocales: [],
};

const localizationSlice = createAppSlice({
  name: "i18n",
  initialState,

  reducers: (create) => ({
    setLocale: create.asyncThunk(
      async (locale: string, { getState }): Promise<string> => {
        const state = getState() as RootState;
        const loadedLocales = localizationSlice.selectors.getLoadedLocales(state);

        if (loadedLocales.includes(locale)) {
          i18n.locale = locale;
          return locale;
        }

        const targetLocale = config.SUPPORTED_LOCALES.includes(locale)
          ? locale
          : config.DEFAULT_LOCALE;
        const translations = await requests.loadTranslations(targetLocale);

        i18n.store({
          [targetLocale]: translations,
        });

        i18n.locale = targetLocale;

        return targetLocale;
      },
      {
        pending: (state) => {
          state.status = status.PENDING;
        },
        fulfilled: (state, action) => {
          const locale = action.payload;

          state.status = status.IDLE;
          state.currentLocale = locale;

          if (!state.loadedLocales.includes(locale)) {
            state.loadedLocales.push(locale);
          }
        },
        rejected: (state) => {
          state.status = status.FAILED;
        },
      },
    ),
  }),

  selectors: {
    getCurrentLocale: (state) => state.currentLocale,
    getLoadedLocales: (state) => state.loadedLocales,
  },
});

const { actions, selectors } = localizationSlice;

export { localizationSlice, actions, selectors };
