// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l-navigation-header--Gxq3s{background-color:var(--bg-primary);color:var(--text-primary);display:flex;border-bottom:1px solid #333;height:48px}.l-navigation-header--compact--gKj_h{display:inline-flex}.l-navigation-header__button-wrapper--VK6Av{display:flex;align-items:center;justify-content:center;min-width:50px;max-width:50px;border-right:1px solid #333;cursor:pointer}.l-navigation-header__title--TQUaF{width:calc(100% - 50px);padding:12px}`, "",{"version":3,"sources":["webpack://./src/modules/navigation/components/NavigationHeader/NavigationHeader.module.scss"],"names":[],"mappings":"AAAA,4BACE,kCAAA,CACA,yBAAA,CACA,YAAA,CACA,4BAAA,CACA,WAAA,CAIA,qCACE,mBAAA,CAGF,4CACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAVmB,CAWnB,cAXmB,CAYnB,2BAAA,CACA,cAAA,CAGF,mCACE,uBAAA,CACA,YAAA","sourcesContent":[".l-navigation-header {\n  background-color: var(--bg-primary);\n  color: var(--text-primary);\n  display: flex;\n  border-bottom: 1px solid #333333;\n  height: 48px;\n\n  $buttonWrapperWidth: 50px;\n\n  &--compact {\n    display: inline-flex;\n  }\n\n  &__button-wrapper {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    min-width: $buttonWrapperWidth;\n    max-width: $buttonWrapperWidth;\n    border-right: 1px solid #333333;\n    cursor: pointer;\n  }\n\n  &__title {\n    width: calc(100% - $buttonWrapperWidth);\n    padding: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"l-navigation-header": `l-navigation-header--Gxq3s`,
	"lNavigationHeader": `l-navigation-header--Gxq3s`,
	"l-navigation-header--compact": `l-navigation-header--compact--gKj_h`,
	"lNavigationHeaderCompact": `l-navigation-header--compact--gKj_h`,
	"l-navigation-header__button-wrapper": `l-navigation-header__button-wrapper--VK6Av`,
	"lNavigationHeader__buttonWrapper": `l-navigation-header__button-wrapper--VK6Av`,
	"l-navigation-header__title": `l-navigation-header__title--TQUaF`,
	"lNavigationHeader__title": `l-navigation-header__title--TQUaF`
};
export default ___CSS_LOADER_EXPORT___;
