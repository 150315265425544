import { Schema } from "prosemirror-model";
import { schema } from "prosemirror-schema-basic";
import { variableMark } from "./plugins/variablesHighlighterPlugin";

const extendedSchema = new Schema({
  nodes: schema.spec.nodes
    .remove("blockquote")
    .remove("heading")
    .remove("code_block")
    .remove("image"),
  marks: {
    highlight: variableMark,
  },
});

export { extendedSchema };
