// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l-navigation-content--FfOJ0{flex-grow:1;flex-basis:100%;padding:16px 12px;max-height:calc(100vh - 49px);overflow:auto}`, "",{"version":3,"sources":["webpack://./src/modules/navigation/components/NavigationContent/NavigationContent.module.scss"],"names":[],"mappings":"AAAA,6BACE,WAAA,CACA,eAAA,CACA,iBAAA,CACA,6BAAA,CACA,aAAA","sourcesContent":[".l-navigation-content {\n  flex-grow: 1;\n  flex-basis: 100%;\n  padding: 16px 12px;\n  max-height: calc(100vh - 49px);\n  overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"l-navigation-content": `l-navigation-content--FfOJ0`,
	"lNavigationContent": `l-navigation-content--FfOJ0`
};
export default ___CSS_LOADER_EXPORT___;
