// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l-execution-logs--iKDpu{max-height:70%;position:absolute;bottom:0;left:0;right:0;background-color:var(--bg-primary);display:flex;flex-direction:column;z-index:2}.l-execution-logs__header--GtA_u{border-top:1px solid var(--border-primary);border-bottom:1px solid var(--border-primary);padding:8px 12px 8px 24px;display:flex;justify-content:space-between}.l-execution-logs__header--GtA_u>*{min-width:0}.l-execution-logs__header-title--CRyHM{display:inline-flex;gap:8px;align-items:center}.l-execution-logs__logs--pkwc5{height:inherit;overflow-y:auto}`, "",{"version":3,"sources":["webpack://./src/modules/editor/components/ExecutionLogs/ExecutionLogs.module.scss"],"names":[],"mappings":"AAAA,yBACE,cAAA,CACA,iBAAA,CACA,QAAA,CACA,MAAA,CACA,OAAA,CACA,kCAAA,CACA,YAAA,CACA,qBAAA,CACA,SAAA,CAEA,iCACE,0CAAA,CACA,6CAAA,CACA,yBAAA,CAEA,YAAA,CACA,6BAAA,CAEA,mCACE,WAAA,CAIJ,uCACE,mBAAA,CACA,OAAA,CACA,kBAAA,CAGF,+BAEE,cAAA,CACA,eAAA","sourcesContent":[".l-execution-logs {\n  max-height: 70%;\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  background-color: var(--bg-primary);\n  display: flex;\n  flex-direction: column;\n  z-index: 2;\n\n  &__header {\n    border-top: 1px solid var(--border-primary);\n    border-bottom: 1px solid var(--border-primary);\n    padding: 8px 12px 8px 24px;\n\n    display: flex;\n    justify-content: space-between;\n\n    > * {\n      min-width: 0;\n    }\n  }\n\n  &__header-title {\n    display: inline-flex;\n    gap: 8px;\n    align-items: center;\n  }\n\n  &__logs {\n    //padding: 16px 0;\n    height: inherit;\n    overflow-y: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"l-execution-logs": `l-execution-logs--iKDpu`,
	"lExecutionLogs": `l-execution-logs--iKDpu`,
	"l-execution-logs__header": `l-execution-logs__header--GtA_u`,
	"lExecutionLogs__header": `l-execution-logs__header--GtA_u`,
	"l-execution-logs__header-title": `l-execution-logs__header-title--CRyHM`,
	"lExecutionLogs__headerTitle": `l-execution-logs__header-title--CRyHM`,
	"l-execution-logs__logs": `l-execution-logs__logs--pkwc5`,
	"lExecutionLogs__logs": `l-execution-logs__logs--pkwc5`
};
export default ___CSS_LOADER_EXPORT___;
