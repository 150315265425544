import type { PromptGroup } from "../../types/PromptGroup";

function create(
  promptData: Omit<PromptGroup, "id">,
  options: { timeout?: number } = {},
): Promise<PromptGroup> {
  return new Promise((res) => {
    setTimeout(() => {
      const promptGroups = localStorage.getItem("__promptGroups");
      const data = promptGroups ? JSON.parse(promptGroups) : [];

      const item = {
        ...promptData,
        id: `prompt-${Date.now()}-${Math.random()}`,
      };

      data.push(item);
      localStorage.setItem("__promptGroups", JSON.stringify(data));

      res(item);
    }, options.timeout || 1000);
  });
}

export default create;
