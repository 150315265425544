import { type Schema, type Node, DOMParser as ProseMirrorDOMParser } from "prosemirror-model";
import { extendedSchema } from "../schema";

function parseEditorContent(content: string, schema: Schema = extendedSchema): Node {
  const paragraphs = content
    .split("\n")
    .map((line) =>
      schema.nodes.paragraph.create(null, line === "" ? schema.text("\u00A0") : schema.text(line)),
    );

  return schema.nodes.doc.create(null, paragraphs);
}

function parseEditorHtmlContent(content: string, schema: Schema = extendedSchema) {
  const sourceMarkup = document.createElement("div");

  sourceMarkup.innerHTML = content;

  return ProseMirrorDOMParser.fromSchema(schema).parse(sourceMarkup);
}

export { parseEditorContent, parseEditorHtmlContent };
