import React, { useEffect, useImperativeHandle, useRef } from "react";
import { EditorState } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { exampleSetup } from "prosemirror-example-setup";
import { variablesHighlighterPlugin } from "./plugins/variablesHighlighterPlugin";
import { parseEditorContent } from "./helpers/parseEditorContent";
import { extendedSchema } from "./schema";

import "prosemirror-view/style/prosemirror.css";
import "./PromptCodeEditor.scss";

interface PromptCodeEditorProps {
  defaultValue?: string;
  onChange?: (content: string) => void;
}

interface Handlers {
  onChange?: PromptCodeEditorProps["onChange"];
}

export interface EditorAPI {
  getContent: () => string;
  getMarkupContent: () => string;
  setContent: (content: string) => void;
  focus: () => void;
}

const PromptCodeEditor = React.forwardRef<EditorAPI, PromptCodeEditorProps>((props, ref) => {
  const { defaultValue = "", onChange } = props;
  const editorRef = useRef(null);
  const viewRef = useRef<EditorView | null>(null);
  const handlersRef = useRef<Handlers>({});

  handlersRef.current = { onChange };

  useImperativeHandle(
    ref,
    () => ({
      getContent() {
        // return viewRef.current ? viewRef.current.state.doc.textBetween(0, viewRef.current.state.doc.content.size, "\n") : "";
        return viewRef.current
          ? viewRef.current.state.doc.textBetween(0, viewRef.current.state.doc.content.size, "\n")
          : "";
      },

      getMarkupContent() {
        return viewRef.current ? viewRef.current.dom.innerHTML : "";
      },

      setContent(content: string) {
        if (viewRef.current) {
          const { state, dispatch } = viewRef.current;
          const { tr } = state;

          tr.replaceWith(0, state.doc.content.size, parseEditorContent(content));
          dispatch(tr);
        }
      },

      focus() {
        viewRef.current?.focus();
      },
    }),
    [],
  );

  useEffect(() => {
    if (editorRef.current) {
      viewRef.current = new EditorView(editorRef.current, {
        state: EditorState.create({
          doc: parseEditorContent(defaultValue, extendedSchema),
          schema: extendedSchema,
          plugins: [
            variablesHighlighterPlugin(),
            ...exampleSetup({ schema: extendedSchema, menuBar: false }),
          ],
        }),

        dispatchTransaction(tr) {
          const newState = viewRef.current!.state.apply(tr);
          viewRef.current!.updateState(newState);

          handlersRef.current.onChange?.(
            newState.doc.textBetween(0, newState.doc.content.size, "\n"),
          );
        },
      });

      return () => {
        viewRef.current!.destroy();
        viewRef.current = null;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="l-prompt-code-editor">
      <div
        className="l-prompt-code-editor__editor"
        ref={editorRef}
      />
    </div>
  );
});

PromptCodeEditor.displayName = "PromptCodeEditor";

export { PromptCodeEditor };
