import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { Button, Box, InputText, Text, Stack, Alert } from "@gusarov-studio/rubik-ui";
import GoogleAuthContainer from "../containers/GoogleAuthContainer";
import { routesMap } from "../../../router/routesMap";
import { signInWithEmailAndPassword } from "../requests/signInWithEmailAndPassword";
import { CenteredColumnLayout } from "../../../common/components/Layout";
import { useTranslator } from "../../localization";
import { Divider } from "../components/Divider";
import type { AuthFormData } from "../types";

function LoginScreen() {
  const [serverError, setServerError] = useState<string>("");
  const [searchParams] = useSearchParams();
  const {
    register,
    formState: { errors },
    clearErrors,
    handleSubmit,
  } = useForm<AuthFormData>({
    defaultValues: {
      email: searchParams.get("email") || "",
      password: "",
    },
    reValidateMode: "onBlur",
  });
  const navigate = useNavigate();
  const i18n = useTranslator();

  const navigateToScheduling = useCallback(() => {
    navigate(routesMap.onboardingForm.url());
  }, [navigate]);

  const onGoogleAuthError = useCallback(() => {
    setServerError(i18n.t("errors.unknown"));
  }, [i18n]);

  const attemptLogin = useCallback(
    async (data: AuthFormData) => {
      const { email, password } = data;

      signInWithEmailAndPassword(email, password)
        .then(() => {
          navigateToScheduling();
        })
        .catch((e) => {
          console.log(e);
          // switch (e.code) {
          // case ERROR_CODES.INVALID_EMAIL:
          //   setSignupError(i18n.t(["SignupScreen", "firebaseInvalidEmail"]));
          //   break;
          // case ERROR_CODES.WEAK_PASSWORD:
          //   setSignupError(i18n.t(["SignupScreen", "firebaseInvalidPassword"]));
          //   break;
          // default:
          //   setSignupError(i18n.t(["SignupScreen", "firebaseError"]));
          //   break;
          // }

          setServerError(i18n.t("errors.unknown"));
        });
    },
    [i18n, navigateToScheduling],
  );

  return (
    <CenteredColumnLayout
      termsOfUse={{
        title: i18n.t("resources.termsOfUse"),
        href: "#",
      }}
      privacyPolicy={{
        title: i18n.t("resources.privacyPolicy"),
        href: "#",
      }}
      copyright={i18n.t("copyright")}
    >
      <Text
        style={{ textAlign: "center" }}
        block
        size="display-md"
        weight="semibold"
      >
        {i18n.t("auth.loginForm.title")}
      </Text>

      <Box
        marginTop="spacing-4xl"
        marginBottom="spacing-xl"
      >
        <GoogleAuthContainer
          onError={onGoogleAuthError}
          onSuccess={navigateToScheduling}
        />
      </Box>

      <Divider message={i18n.t("auth.or")} />

      {serverError && (
        <Box marginTop="spacing-xl">
          <Alert variant="danger">
            <Text
              size="regular-sm"
              appearance="error"
            >
              {serverError}
            </Text>
          </Alert>
        </Box>
      )}

      <Box marginTop="spacing-xl">
        <form onSubmit={handleSubmit(attemptLogin)}>
          <Stack gap="xs">
            <Text
              as="label"
              htmlFor="email"
              size="regular-sm"
              block
              truncate
            >
              {i18n.t("auth.inputs.email.label")}
            </Text>

            <InputText
              id="email"
              type="email"
              invalid={Boolean(errors.email)}
              placeholder={i18n.t("auth.inputs.email.placeholder")}
              onInput={() => {
                if (errors.email) clearErrors("email");
              }}
              {...register("email", {
                required: true,
                pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
              })}
            />

            {errors.email && (
              <Text
                appearance="error"
                size="regular-sm"
                block
                truncate
              >
                {i18n.t("errors.inputs.invalid", {
                  field: "Email",
                })}
              </Text>
            )}
          </Stack>

          <Box marginTop="spacing-xl">
            <Stack gap="xs">
              <Text
                as="label"
                htmlFor="password"
                size="regular-sm"
                truncate
              >
                {i18n.t("auth.inputs.password.label")}
              </Text>

              <InputText
                id="password"
                type="password"
                invalid={Boolean(errors.password)}
                placeholder={i18n.t("auth.inputs.password.placeholder")}
                onInput={() => {
                  if (errors.password) clearErrors("password");
                }}
                {...register("password", {
                  required: true,
                })}
              />

              {errors.password && (
                <Text
                  as="span"
                  appearance="error"
                  size="regular-sm"
                  block
                  truncate
                >
                  {i18n.t("errors.inputs.required", {
                    field: "Password",
                  })}
                </Text>
              )}
            </Stack>
          </Box>

          <Box marginTop="spacing-3xl">
            <Button
              variant="primary"
              type="submit"
              shape="pill"
              fullWidth
              size="40"
            >
              {i18n.t("auth.buttons.continueWithEmail")}
            </Button>
          </Box>
        </form>

        <Box marginTop="spacing-xl">
          <Text size="regular-sm">
            {i18n.t("auth.loginForm.doNotHaveAcc")}{" "}
            <Link to={routesMap.signup.url()}>{i18n.t("auth.loginForm.signup")}!</Link>
          </Text>
        </Box>
      </Box>
    </CenteredColumnLayout>
  );
}

export { LoginScreen };
