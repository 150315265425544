import { combineSlices } from "@reduxjs/toolkit";
import { promptGroupsApi } from "../modules/promptGroups";
import { projectsSlice, projectsApi } from "../modules/projects";
import { promptsApi } from "../modules/prompts";
import { navigationSlice } from "../modules/navigation";
import { localizationSlice } from "../modules/localization";
import { editorSlice } from "../modules/editor";
import { reportsApi } from "../modules/reports";
import { dialogsSlice } from "../modules/dialogs";
import { onboardingApi } from "../modules/onboardingQuestionare";

function createAppReducer() {
  return combineSlices(
    // api
    projectsApi,
    promptsApi,
    promptGroupsApi,
    reportsApi,
    onboardingApi,

    //slices
    projectsSlice,
    navigationSlice,
    localizationSlice,
    editorSlice,
    dialogsSlice,
  );
}

export default createAppReducer;
