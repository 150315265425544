import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { NavigationContainer } from "../modules/navigation/containers/NavigationContainer";
import styles from "./App.module.scss";
import { navigation } from "../modules/navigation";
import clsx from "clsx";
import { projects, useGetProjectQuery } from "../modules/projects";
import { DialogsManagerContainer } from "../modules/dialogs/containers/DialogsManagerContainer";
import { routesMap } from "../router/routesMap";

const App = () => {
  const activeProjectId = useAppSelector(projects.selectors.getActiveProjectId);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { data: activeProject, isLoading } = useGetProjectQuery(activeProjectId as string, {
    skip: !activeProjectId,
  });

  useEffect(() => {
    dispatch(projects.actions.setActiveProject("project-1724692775209-0.30607673379168276"));
  }, [dispatch]);

  const isNavigationVisible = useAppSelector(navigation.selectors.getVisibilityState);

  useEffect(() => {
    if (location.pathname === "/") {
      navigate(routesMap.onboardingScheduling.url());
    }
  }, [location, navigate]);

  if (isLoading || !activeProject) return null;

  return (
    <div className={styles["lApp"]}>
      <NavigationContainer />

      <main
        className={clsx(styles["lApp__main"], {
          [styles["lApp__mainNavigationOpened"]]: isNavigationVisible,
        })}
      >
        <Outlet />
      </main>

      <DialogsManagerContainer />
    </div>
  );
};

export { App };
