import config from "@lisapet/config";
import {
  signInWithRedirect,
  signInWithPopup,
  browserSessionPersistence,
  setPersistence,
} from "firebase/auth";
import { auth, provider } from "../instances";

async function signInWithGoogle() {
  try {
    await setPersistence(auth, browserSessionPersistence);
    if (["development", "preview"].includes(config.env)) {
      return await signInWithPopup(auth, provider);
    } else {
      return await signInWithRedirect(auth, provider);
    }
  } catch (error) {
    console.error("Failed to set persistence:", error);
    throw error;
  }
}

export { signInWithGoogle };
