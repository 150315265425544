import { VARIABLE_REGEXP } from "../constants/regexps";

function extractVariables(content: string): string[] {
  const variables = new Set<string>();
  const regex = VARIABLE_REGEXP;

  let match = regex.exec(content);

  while (match !== null) {
    variables.add(match[1].trim());

    match = regex.exec(content);
  }

  return Array.from(variables);
}

export { extractVariables };
