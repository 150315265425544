import React from "react";
import { useParams } from "react-router-dom";
import { PromptEditorContainer } from "../containers/PromptEditorContainer";
import { ReportsEditorContainer } from "../containers/ReportsEditorContainer";
import { EditorLayout } from "../components/EditorLayout";
import { fileTypes } from "../../../common/constants/fileTypes";

function EditFileScreen() {
  const { fileId, fileType } = useParams();

  return (
    <EditorLayout>
      {fileType === fileTypes.PROMPT && <PromptEditorContainer id={fileId!} />}
      {fileType === fileTypes.REPORT && <ReportsEditorContainer id={fileId!} />}
    </EditorLayout>
  );
}

export { EditFileScreen };
