import React from "react";
import { Text } from "@gusarov-studio/rubik-ui";
import { Avatar } from "../Avatar";
import styles from "./Profile.module.scss";

interface ProfileProps {
  name?: string | null;
  email?: string | null;
  logoSrc?: string | null;
  altText?: string;
  logoFallback: string;
}

function Profile(props: ProfileProps) {
  const { name, email, logoSrc, logoFallback, altText } = props;

  return (
    <div className={styles["lProfile"]}>
      <div className={styles["lProfileAvatar"]}>
        <Avatar
          src={logoSrc}
          fallback={logoFallback}
          altText={altText}
        />
      </div>

      <div className={styles["lProfileInfo"]}>
        {name && (
          <Text
            truncate
            weight="semibold"
            size="regular-sm"
          >
            {name}
          </Text>
        )}

        {email && (
          <Text
            truncate
            size="regular-sm"
          >
            {email}
          </Text>
        )}
      </div>
    </div>
  );
}

export { Profile };
