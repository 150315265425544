import { useMemo } from "react";
import { aiModels } from "../../../common/constants/aiModels";

function useSupportedAiModels() {
  return useMemo(
    () =>
      Object.values(aiModels).map((item) => ({
        title: item,
        value: item,
      })),
    [],
  );
}

export { useSupportedAiModels };
