import React, { useContext } from "react";
import styles from "./PromptSetup.module.scss";
import { Button, ThemesContext } from "@gusarov-studio/rubik-ui";
import { clsx } from "clsx";
import type { Prompt, NewPrompt } from "../../../../types/Prompt";

interface PromptEditorProps extends React.PropsWithChildren {
  prompt: NewPrompt | Prompt;
  sidebar?: React.ReactNode;
  disabled?: boolean;
  onRunClick: React.MouseEventHandler;
  onLogClick?: React.MouseEventHandler;
}

function PromptSetup(props: PromptEditorProps) {
  const { sidebar, children, onRunClick, onLogClick } = props;
  const themesContext = useContext(ThemesContext);

  return (
    <div className={styles["lPromptSetup"]}>
      <div className={clsx(styles["lPromptSetup__controlPanel"], themesContext.themes.dark)}>
        <div>
          <Button
            size="32"
            variant="accent"
            onClick={onRunClick}
          >
            Run prompt
          </Button>

          {onLogClick && (
            <Button
              size="32"
              variant="text-accent"
              onClick={onLogClick}
              style={{ marginLeft: "8px" }}
            >
              Show logs
            </Button>
          )}
        </div>

        <div />
      </div>

      <div className={styles["lPromptSetup__container"]}>
        <div className={styles["lPromptSetup__playgroundContainer"]}>{children}</div>

        {sidebar && <div className={styles["lPromptSetup__sidebar"]}>{sidebar}</div>}
      </div>
    </div>
  );
}

export { PromptSetup };
