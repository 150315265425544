import React from "react";
import styles from "./ExecutionLog.module.scss";
import { Text, Icon, BrandIcon, type BrandIconGlyph } from "@gusarov-studio/rubik-ui";
import { logTypes } from "../../constants/logTypes";
import clsx from "clsx";
import type { Log } from "../../types";
import { ExecutionResponse } from "../ExecutionResponse";

interface ExecutionLogProps {
  onResponseClick: React.MouseEventHandler;
  log: Log;
  labels: {
    title: string;
    buttonText: string;
    cost: string;
    responseTime: string;
    tokens: string;
  };
}

function ExecutionLog(props: ExecutionLogProps) {
  const { log, labels, onResponseClick } = props;

  return (
    <div
      className={clsx(styles["lExecutionLog"], {
        [styles["lExecutionLogTypeInfo"]]: log.type === logTypes.INFO,
        [styles["lExecutionLogTypeResult"]]: log.type === logTypes.RESULT,
      })}
    >
      <div className={styles["lExecutionLog__time"]}>
        <Text
          size="regular-sm"
          weight="medium"
        >
          {log.time}
        </Text>
      </div>

      <div className={styles["lExecutionLog__icon"]}>
        {logTypes.INFO === log.type && (
          <Icon
            glyph="server-01"
            size={24}
          />
        )}

        {logTypes.RESULT === log.type && (
          <BrandIcon
            glyph={log.data.llvm as BrandIconGlyph}
            size={24}
          />
        )}
      </div>

      <div className={styles["lExecutionLog__info"]}>
        {log.type === logTypes.INFO && (
          <Text
            size="regular-sm"
            weight="medium"
          >
            {log.data.message}
          </Text>
        )}

        {log.type === logTypes.RESULT && (
          <ExecutionResponse
            log={log}
            labels={{
              title: labels.title,
              buttonText: labels.buttonText,
              tokens: labels.tokens,
              cost: labels.cost,
              responseTime: labels.responseTime,
            }}
            onClick={onResponseClick}
          />
        )}
      </div>
    </div>
  );
}

export { ExecutionLog };
