import config from "@lisapet/config";
import {
  signInWithRedirect,
  signInWithPopup,
  browserSessionPersistence,
  setPersistence,
} from "firebase/auth";
import { auth, provider } from "../instances";

async function signInWithGoogle() {
  try {
    await setPersistence(auth, browserSessionPersistence);
    switch (config.get("env")) {
      case "development":
      case "local": {
        return await signInWithPopup(auth, provider);
      }
      default: {
        return await signInWithRedirect(auth, provider);
      }
    }
  } catch (error) {
    console.error("Failed to set persistence:", error);
    throw error;
  }
}

export { signInWithGoogle };
