import React, { useCallback } from "react";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { navigation } from "../index";
import { NavigationHeader } from "../components/NavigationHeader";

function NavigationCompactContainer() {
  const dispatch = useAppDispatch();
  const isNavigationVisible = useAppSelector(navigation.selectors.getVisibilityState);

  const onClick = useCallback(() => {
    dispatch(navigation.actions.show());
  }, [dispatch]);

  if (isNavigationVisible) return null;

  return (
    <NavigationHeader
      onClick={onClick}
      compact
    />
  );
}

export { NavigationCompactContainer };
