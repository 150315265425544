import { getRedirectResult } from "firebase/auth";
import { auth } from "../instances";
import { trackUserCreation } from "../../analytics/gtm";

async function getSignupRedirectResults() {
  const result = await getRedirectResult(auth);

  if (!result) return result;
  trackUserCreation(result);

  return result;
}

export { getSignupRedirectResults };
