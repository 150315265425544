import { getAuth, fetchSignInMethodsForEmail } from "firebase/auth";

async function accountExists(email: string) {
  const auth = getAuth();

  const methods = await fetchSignInMethodsForEmail(auth, email);
  return Array.isArray(methods) && methods.length > 0;
}

export { accountExists };
