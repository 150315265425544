import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { Box, Text } from "@gusarov-studio/rubik-ui";
import { navigation } from "../index";
import { routesMap } from "../../../router/routesMap";

import { NavigationHeader } from "../components/NavigationHeader";
import { Navigation } from "../components/Navigation";
import { NavigationContent } from "../components/NavigationContent";
import { NavigationGroup } from "../components/NavigationGroup";
import { SearchBar } from "../components/SearchBar";
import type { ListItem, ItemsGroup } from "../types";
import { useGetPromptGroupsQuery } from "../../promptGroups";
import { useGetReportsQuery } from "../../reports";
import { useGetPromptsQuery } from "../../prompts";
import { useGetProjectQuery } from "../../projects";
import { ProfileContainer } from "./ProfileContainer";
import { fileTypes } from "../../../common/constants/fileTypes";
import { getModelIcon } from "../../../common/helpers/getModelIcon";

function NavigationContainer() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: project } = useGetProjectQuery("project-1724692775209-0.30607673379168276");
  const { data: reportList = [], isLoading: isReportListLoading } = useGetReportsQuery();
  const { data: promptGroupList = [], isLoading: isPromptGroupListLoading } =
    useGetPromptGroupsQuery();

  const { data: promptList = [], isLoading: isPromptListLoading } = useGetPromptsQuery();
  const isNavigationVisible = useAppSelector(navigation.selectors.getVisibilityState);
  const [selectedItems, setSelectedItems] = useState<ListItem[]>([]);

  const onSelect = useCallback(
    (item: ListItem) => {
      navigate(
        routesMap.editFile.url({ fileType: item.type, fileId: item.id, fileName: item.name }),
      );
    },
    [navigate],
  );

  const onNavigationClick = useCallback(() => {
    dispatch(navigation.actions.hide());
  }, [dispatch]);

  const reportGroupItems = useMemo(() => {
    return reportList.map(
      (item): ListItem => ({
        ...item,
        name: item.title,
        glyph: "pie-chart-01",
        type: fileTypes.REPORT,
      }),
    );
  }, [reportList]);

  const promptGroupItems = useMemo(
    () =>
      promptGroupList.map(
        (promptGroup): ItemsGroup => ({
          ...promptGroup,
          items: promptList.reduce((result: ListItem[], prompt) => {
            if (prompt.promptGroupId !== promptGroup.id) return result;

            result.push({
              ...prompt,
              groupId: prompt.promptGroupId,
              type: fileTypes.PROMPT,
              brand: getModelIcon(prompt.llvm),
            });

            return result;
          }, []),
        }),
      ),
    [promptGroupList, promptList],
  );

  const onHeaderClick = useCallback((group: ItemsGroup) => {
    console.log(group);
  }, []);

  const onItemsGroupSelect = useCallback((activeEventKeys?: string | string[] | null) => {
    if (Array.isArray(activeEventKeys)) {
      setSelectedItems((selectedItemsState) => {
        const updatedItems = selectedItemsState.filter((selectedItem) =>
          activeEventKeys.includes(selectedItem.groupId!),
        );

        if (updatedItems.length === selectedItemsState.length) {
          return selectedItemsState;
        }

        return updatedItems;
      });
    }
  }, []);

  const onItemClick = useCallback((event: React.MouseEvent, prompt: ListItem) => {
    setSelectedItems((selectedItemsState) => {
      if (event.ctrlKey || event.metaKey) {
        if (selectedItemsState.includes(prompt)) {
          return selectedItemsState.filter((selectedItem) => selectedItem.id !== prompt.id);
        } else {
          return [...selectedItemsState, prompt];
        }
      }

      return [prompt];
    });
  }, []);

  const onItemDoubleClick = useCallback(
    (event: React.MouseEvent, item: ListItem) => {
      console.log(item);
      setSelectedItems([item]);

      onSelect(item);
    },
    [onSelect],
  );

  const isLoading = isReportListLoading || isPromptGroupListLoading || isPromptListLoading;

  if (!isNavigationVisible) return null;

  return (
    <Navigation>
      <NavigationHeader
        title={project!.name}
        onClick={onNavigationClick}
      />

      <Box
        marginTop="spacing-2xl"
        paddingLeft="spacing-xl"
        paddingRight="spacing-xl"
      >
        <SearchBar />
      </Box>

      <NavigationContent>
        {isLoading && <Text>Loading...</Text>}
        {!isLoading && (
          <>
            <NavigationGroup
              title={"Prompts"}
              titleGlyph="prompt"
              sublistGlyph="folder"
              items={promptGroupItems}
              selectedItems={selectedItems}
              onItemClick={onItemClick}
              onItemDoubleClick={onItemDoubleClick}
              onHeaderClick={onHeaderClick}
              onItemsGroupSelect={onItemsGroupSelect}
            />

            <Box marginTop="spacing-md">
              <NavigationGroup
                title={"Reports"}
                titleGlyph="pie-chart-01"
                sublistGlyph="pie-chart-01"
                items={reportGroupItems}
                selectedItems={selectedItems}
                onItemClick={onItemClick}
                onItemDoubleClick={onItemDoubleClick}
                onHeaderClick={onHeaderClick}
                onItemsGroupSelect={onItemsGroupSelect}
              />
            </Box>
          </>
        )}
      </NavigationContent>

      <ProfileContainer />
    </Navigation>
  );
}

export { NavigationContainer };
