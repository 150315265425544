import type { Action, ThunkAction } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import createAppReducer from "./createAppReducer";
import { promptGroupsApi } from "../modules/promptGroups";
import { reportsApi } from "../modules/reports";
import { projectsApi } from "../modules/projects";
import { promptsApi } from "../modules/prompts";
import { onboardingApi } from "../modules/onboardingQuestionare";

const rootReducer = createAppReducer();

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["dialogs/open"],
        ignoredPaths: ["dialogs.props"],
      },
    }).concat([
      promptGroupsApi.middleware,
      reportsApi.middleware,
      projectsApi.middleware,
      promptsApi.middleware,
      onboardingApi.middleware,
    ]),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = typeof store;
export type AppDispatch = AppStore["dispatch"];
export type AppThunk<ThunkReturnType = void> = ThunkAction<
  ThunkReturnType,
  RootState,
  unknown,
  Action
>;
