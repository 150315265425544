import type { PayloadAction } from "@reduxjs/toolkit";
import { createAppSlice } from "../../../store/createAppSlice";
import type { EditorTab } from "../types";

interface EditorState {
  tabs: EditorTab[];
  activeTabId: EditorTab["id"] | null;
}

const initialState: EditorState = {
  tabs: [],
  activeTabId: null,
};

const editorSlice = createAppSlice({
  name: "editor",
  initialState,
  reducers: {
    addTab: (state, action: PayloadAction<EditorTab>) => {
      const { id: tabId } = action.payload;
      const isAlreadyOpened = state.tabs.some((tab) => tab.id === tabId);

      if (!isAlreadyOpened) {
        state.tabs.push(action.payload);
      }

      state.activeTabId = tabId;
    },
    removeTab: (state, action: PayloadAction<EditorTab["id"]>) => {
      const tabId = action.payload;
      const tabIndex = state.tabs.findIndex((tab) => tab.id === tabId);

      if (tabIndex < 0) return;

      const [removedTab] = state.tabs.splice(tabIndex, 1);

      if (state.activeTabId === removedTab.id) {
        state.activeTabId = null;
      }
    },
    setActiveTab: (state, action: PayloadAction<EditorTab["id"]>) => {
      state.activeTabId = action.payload;
    },
  },

  selectors: {
    getTabs: (state) => state.tabs,
    getActiveTabId: (state) => state.activeTabId,
    getActiveTab: (state) => state.tabs.find((tab) => tab.id === state.activeTabId) || null,
  },
});

const { selectors, actions } = editorSlice;

export { editorSlice, selectors, actions };
