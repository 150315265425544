// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l-search-bar--ehA94{display:flex;align-items:flex-start;gap:8px}.l-search-bar--ehA94>*:first-child{min-width:0}`, "",{"version":3,"sources":["webpack://./src/modules/navigation/components/SearchBar/SearchBar.module.scss"],"names":[],"mappings":"AAAA,qBACE,YAAA,CACA,sBAAA,CACA,OAAA,CAEA,mCACE,WAAA","sourcesContent":[".l-search-bar {\n  display: flex;\n  align-items: flex-start;\n  gap: 8px;\n\n  > *:first-child {\n    min-width: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"l-search-bar": `l-search-bar--ehA94`,
	"lSearchBar": `l-search-bar--ehA94`
};
export default ___CSS_LOADER_EXPORT___;
