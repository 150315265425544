import { getAuth, signOut as signOutFirebase } from "firebase/auth";
import firebaseApp from "@lisapet/firebase";

const signOut = () => {
  const auth = getAuth(firebaseApp);

  return signOutFirebase(auth);
};

export { signOut };
