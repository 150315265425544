import React from "react";
import { Box, InputText, Stack, Text } from "@gusarov-studio/rubik-ui";
import { type PlaygroundForm, type Variable } from "../../types";
import { type Control, Controller } from "react-hook-form";

interface VariablesProps {
  variables: Variable[];
  control: Control<PlaygroundForm>;
}

function Variables(props: VariablesProps) {
  const { variables, control } = props;

  return (
    <Box padding="spacing-xl">
      <Stack gap="md">
        {variables.map((variable, index) => (
          <Controller
            control={control}
            key={variable.name}
            name={`variables.${index}.value`}
            render={({ field }) => (
              <Stack
                gap="xs"
                key={field.name}
              >
                <Text
                  block
                  as="label"
                  htmlFor={variable.name}
                  weight="semibold"
                >
                  <Text>{"{{ "}</Text>
                  {variable.name}
                  <Text>{" }}"}</Text>
                </Text>

                <InputText
                  id={variable.name}
                  name={field.name}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange((e.target as HTMLInputElement).value);
                  }}
                />
              </Stack>
            )}
          />
        ))}
      </Stack>
    </Box>
  );
}

export { Variables };
