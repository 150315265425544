import React from "react";
import clsx from "clsx";
import { Text } from "@gusarov-studio/rubik-ui";
import styles from "./CenteredColumnLayout.module.scss";
import { useTranslator } from "../../../modules/localization";

const align = {
  CENTER: "center",
};

interface FooterLink {
  href: string;
  title: string;
}

interface LayoutProps extends React.PropsWithChildren {
  textAlign?: string;
  columnSize?: string;
  copyright: string;
  termsOfUse: FooterLink;
  privacyPolicy: FooterLink;
}

function CenteredColumnLayout(props: LayoutProps) {
  const {
    textAlign = "left",
    columnSize = "392px",
    termsOfUse,
    privacyPolicy,
    copyright,
    children,
  } = props;

  return (
    <div className={styles["lCenteredColumn"]}>
      <div
        className={clsx(styles["lCenteredColumn__contentBox"], {
          [styles["lCenteredColumn__contentCenter"]]: textAlign === align.CENTER,
        })}
        style={{
          maxWidth: columnSize,
        }}
      >
        <div className={styles["lCenteredColumn__content"]}>{children}</div>
      </div>

      <div className={styles["lCenteredColumn__footer"]}>
        <Text>{copyright}</Text>

        <span className={styles["lCenteredColumn__footerLinksDivider"]} />
        <a
          className={styles["lCenteredColumn__link"]}
          href={termsOfUse.href}
        >
          {termsOfUse.title}
        </a>

        <span className={styles["lCenteredColumn__footerLinksDivider"]} />
        <a
          className={styles["lCenteredColumn__link"]}
          href={privacyPolicy.href}
        >
          {privacyPolicy.title}
        </a>
      </div>
    </div>
  );
}

export { CenteredColumnLayout };
