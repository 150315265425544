import React from "react";
import { Controller, type Control, type ControllerRenderProps } from "react-hook-form";
import { Button, Icon } from "@gusarov-studio/rubik-ui";
import styles from "./PromptMessages.module.scss";
import { PromptCodeEditor } from "../PromptCodeEditor";
import { capitalize } from "../../../../common/utils/capitalize";
import type { Message, PlaygroundForm } from "../../types";

interface PromptMessagesProps {
  disabled?: boolean;
  onAddMessage: React.MouseEventHandler;
  onMessageRemove: (message: Message, index: number) => void;
  onMessageTypeClick: (field: ControllerRenderProps<PlaygroundForm>) => void;
  messageFields: Message[];
  onChange?: (field: ControllerRenderProps<PlaygroundForm>) => void;
  defaultValue?: string;
  control: Control<PlaygroundForm>;
}

function PromptMessages(props: PromptMessagesProps) {
  const {
    disabled,
    onAddMessage,
    onMessageTypeClick,
    onMessageRemove,
    onChange,
    control,
    messageFields,
  } = props;

  return (
    <div className={styles["lPromptMessages"]}>
      {messageFields.map((messageField, index) => (
        <div
          key={messageField.id}
          className={styles["lPromptMessages__row"]}
        >
          <div className={styles["lPromptMessages__author"]}>
            <Controller
              control={control}
              name={`messages.${index}.type`}
              render={({ field }) => (
                <Button
                  size="32"
                  variant="primary"
                  shape="rounded"
                  onClick={() => {
                    onMessageTypeClick(field);
                  }}
                >
                  {capitalize(field.value || messageField.type)}
                </Button>
              )}
            />
          </div>

          <div className={styles["lPromptMessages__main"]}>
            <Controller
              name={`messages.${index}.value`}
              control={control}
              render={({ field }) => {
                return (
                  <PromptCodeEditor
                    onChange={(value) => {
                      if (field.value !== value) {
                        field.onChange(value);
                      }

                      if (onChange) {
                        onChange(field);
                      }
                    }}
                    defaultValue={messageField.value}
                  />
                );
              }}
            />
          </div>

          <div className={styles["lPromptMessages__interaction"]}>
            <Button
              variant="text-primary"
              onClick={() => {
                onMessageRemove(messageField, index);
              }}
            >
              <Icon
                glyph="minus-circle"
                appearance="inherit"
              />
            </Button>
          </div>
        </div>
      ))}

      <div className={styles["lPromptMessages__row"]}>
        <div className={styles["lPromptMessages__author"]}></div>

        <div className={styles["lPromptMessages__main"]}>
          <Button
            onClick={onAddMessage}
            fullWidth
            disabled={disabled}
          >
            New message
          </Button>
        </div>

        <div className={styles["lPromptMessages__interaction"]}></div>
      </div>
    </div>
  );
}

export { PromptMessages };
