// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l-app--Ry0yK{min-width:100%;max-width:100%;min-height:100%;max-height:100%;height:100%;display:flex}.l-app__navigation--ykbUE{max-width:280px;min-height:100%}.l-app__main--XfBz4{width:100%;height:100%;min-height:100%;overflow:hidden}.l-app__main--navigation-opened--CJxiQ{width:calc(100% - var(--l-opened-navigation-size))}`, "",{"version":3,"sources":["webpack://./src/app/App.module.scss"],"names":[],"mappings":"AAAA,cACE,cAAA,CACA,cAAA,CACA,eAAA,CACA,eAAA,CACA,WAAA,CACA,YAAA,CAEA,0BACE,eAAA,CACA,eAAA,CAGF,oBACE,UAAA,CACA,WAAA,CACA,eAAA,CACA,eAAA,CAEA,uCACE,kDAAA","sourcesContent":[".l-app {\n  min-width: 100%;\n  max-width: 100%;\n  min-height: 100%;\n  max-height: 100%;\n  height: 100%;\n  display: flex;\n\n  &__navigation {\n    max-width: 280px;\n    min-height: 100%;\n  }\n\n  &__main {\n    width: 100%;\n    height: 100%;\n    min-height: 100%;\n    overflow: hidden;\n\n    &--navigation-opened {\n      width: calc(100% - var(--l-opened-navigation-size));\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"l-app": `l-app--Ry0yK`,
	"lApp": `l-app--Ry0yK`,
	"l-app__navigation": `l-app__navigation--ykbUE`,
	"lApp__navigation": `l-app__navigation--ykbUE`,
	"l-app__main": `l-app__main--XfBz4`,
	"lApp__main": `l-app__main--XfBz4`,
	"l-app__main--navigation-opened": `l-app__main--navigation-opened--CJxiQ`,
	"lApp__mainNavigationOpened": `l-app__main--navigation-opened--CJxiQ`
};
export default ___CSS_LOADER_EXPORT___;
