import { createAppSlice } from "../../../store/createAppSlice";

interface NavigationState {
  isVisible: boolean;
}

const initialState: NavigationState = {
  isVisible: true,
};

const navigationSlice = createAppSlice({
  name: "navigation",
  initialState,
  reducers: {
    show(state) {
      state.isVisible = true;
    },

    hide(state) {
      state.isVisible = false;
    },
  },

  selectors: {
    getVisibilityState: (state) => state.isVisible,
  },
});

const { actions, selectors } = navigationSlice;

export { navigationSlice, actions, selectors };
