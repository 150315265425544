import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { aiModels } from "../../../common/constants/aiModels";
import type { NewPrompt } from "../../../types/Prompt";
import { useAddPromptMutation } from "../../prompts";
import { useGetPromptGroupsQuery } from "../../promptGroups";
import { routesMap } from "../../../router/routesMap";
import { fileTypes } from "../../../common/constants/fileTypes";
import { PromptPlayground } from "../components/PromptPlayground";
import { dialogs } from "../../dialogs";
import type { Log, PlaygroundForm, ResponseLog } from "../types";
import { logTypes } from "../constants/logTypes";
import { ExecutionLogs } from "../components/ExecutionLogs";

function CreatePromptContainer() {
  const prompt: NewPrompt = {
    llvm: aiModels.GPT3,
    name: "",
    promptGroupId: "",
  };
  const navigate = useNavigate();
  const { data: promptGroups, isLoading: isGroupsQueryLoading } = useGetPromptGroupsQuery();
  const [addPrompt, { isLoading: isAddProcessing }] = useAddPromptMutation();
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    (data: PlaygroundForm) => {
      dispatch(
        dialogs.actions.open({
          dialogName: dialogs.dialogNames.TEST,
          props: {
            data,
            onClose: () => dispatch(dialogs.actions.close()),
          },
        }),
      );

      const { messages } = data;
      const value = messages[messages.length - 1].value;

      setLogs((currentLogs) => [
        {
          time: "1:12:04 PM",
          type: logTypes.INFO,
          data: {
            message: "Execution started",
          },
        },
        {
          time: "1:12:42 PM",
          type: logTypes.RESULT,
          data: {
            responseTime: "30s",
            cost: 0.0001,
            tokens: 300,
            message: value,
            llvm: "chat-gpt-3",
          },
        },
        {
          time: "1:18:47 PM",
          type: logTypes.INFO,
          data: {
            message: "Execution completed",
          },
        },
        ...currentLogs,
      ]);
    },
    [dispatch],
  );
  const [areLogsVisible, setLogsVisibility] = useState(false);

  const copyToClipboard = (log: ResponseLog) => {
    navigator.clipboard.writeText(log.data.message).then(
      () => {
        console.log("Text copied to clipboard");
      },
      (err) => {
        console.error("Failed to copy text: ", err);
      },
    );
  };

  const [logs, setLogs] = useState<Log[]>(
    [
      {
        time: "1:12:04 PM",
        type: logTypes.INFO,
        data: {
          message: "Execution started",
        },
      },
      {
        time: "1:12:42 PM",
        type: logTypes.RESULT,
        data: {
          responseTime: "30s",
          cost: 0.0001,
          tokens: 300,
          message: JSON.stringify(
            {
              reason:
                "The information provided clearly indicates that the website topic is a car rental business.",
              result: true,
              topic: 321,
            },
            null,
            2,
          ),
          llvm: "chat-gpt-3",
        },
      },
      {
        time: "1:18:47 PM",
        type: logTypes.INFO,
        data: {
          message: "Execution completed",
        },
      },
    ].reverse(),
  );

  const onAdd = useCallback(
    (prompt: NewPrompt) => {
      addPrompt(prompt).then(({ data }) => {
        navigate(
          routesMap.editFile.url({
            fileType: fileTypes.PROMPT,
            fileId: data!.id,
            fileName: data!.name,
          }),
        );
      });
    },
    [addPrompt, navigate],
  );

  const messages = [
    {
      type: "system",
      value: "System messages uses {{variable_1}}",
      id: "dfsf",
    },
    {
      type: "user",
      value: "How do I build a successful engineering team of {{team_size}} members?",
      id: "fdfd",
    },
  ];

  if (isGroupsQueryLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <PromptPlayground
        prompt={prompt}
        promptGroups={promptGroups || []}
        messages={messages}
        onSubmit={onSubmit}
        onLogClick={() => setLogsVisibility((areVisible) => !areVisible)}
        onEdit={onAdd}
        disabled={isAddProcessing}
      />

      {areLogsVisible && (
        <ExecutionLogs
          title="Execution logs"
          onClose={() => setLogsVisibility(false)}
          labels={{
            title: "Result",
            buttonText: "Copy",
            tokens: "Total tokens",
            cost: "Total cost",
            responseTime: "Response time",
          }}
          onResponseClick={copyToClipboard}
          logs={logs}
        />
      )}
    </>
  );
}

export { CreatePromptContainer };
