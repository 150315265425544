import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createSelector } from "@reduxjs/toolkit";
import type { Prompt } from "../../../types/Prompt";
import __ls from "../../../__localStore";
import type { RootState } from "../../../store";

const promptsApi = createApi({
  reducerPath: "api.Prompts",
  baseQuery: fetchBaseQuery({ baseUrl: "/static/mock-api" }),
  tagTypes: ["Prompt"],

  endpoints: (build) => ({
    getPrompts: build.query<Prompt[], void>({
      query: () => "/prompts.json?getPrompts",
      // @ts-expect-error test-purpose
      transformResponse: __ls.createOnGet("__prompts"),
      providesTags: ["Prompt"],
    }),

    getPromptsByGroup: build.query<Prompt[], { groupId: string }>({
      query: ({ groupId }) => `/prompts.json?getPromptsByGroup=${encodeURIComponent(groupId)}`,
      // @ts-expect-error test-purpose
      transformResponse: __ls.createOnGetQuery(
        "__prompts",
        // @ts-expect-error test-purpose
        (item, args) => item.groupId === args.groupId,
      ),
    }),

    addPrompt: build.mutation<Prompt, Omit<Prompt, "id">>({
      query: (promptData) => ({
        url: "/prompts.json?addPrompt",
        // TODO: update method
        method: "GET",
        query: JSON.stringify(promptData),
        // body: promptData
      }),
      // invalidatesTags: ["Prompt"],
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        const tkqResponse = await queryFulfilled;

        dispatch(
          promptsApi.util.updateQueryData("getPrompts", undefined, (draftPrompts) => {
            draftPrompts.push(tkqResponse.data);
          }),
        );
      },
      // TODO: remove transform response
      transformResponse: __ls.createOnAdd("__prompts"),
    }),

    deletePrompt: build.mutation<Prompt, { promptId: string }>({
      query: ({ promptId }) => ({
        url: `/prompts.json?deletePrompt=${promptId}`,
        // TODO: replace with delete
        method: "GET",
      }),
      invalidatesTags: ["Prompt"],
      // TODO: remove transform response
      // @ts-expect-error test-purpose
      transformResponse: __ls.createOnDelete(
        "__prompts",
        // @ts-expect-error test-purpose
        (item, args) => item.id === args.promptId,
      ),
    }),

    updatePrompt: build.mutation<Prompt, Partial<Prompt>>({
      query: (prompt) => ({
        url: `/prompts.json?updatePrompt=${prompt.id}`,
        // TODO: replace with update
        method: "GET",
        // body: promptData
      }),
      invalidatesTags: ["Prompt"],
      // @ts-expect-error test-purpose
      transformResponse: __ls.createOnUpdate("__prompts", (item, args) => item.id === args.id),
    }),
  }),
});

const getPromptsResult = promptsApi.endpoints.getPrompts.select();

const getPrompts = createSelector(getPromptsResult, (promptsResult) => promptsResult?.data ?? []);

const getPromptById = createSelector(
  getPrompts,
  (rootState: RootState, promptId: string) => promptId,
  (prompts: Prompt[], promptId) => prompts.find((prompt) => prompt.id === promptId),
);

const selectors = {
  getPrompts,
  getPromptById,
};

const {
  useGetPromptsQuery,
  useGetPromptsByGroupQuery,
  useAddPromptMutation,
  useDeletePromptMutation,
  useUpdatePromptMutation,
} = promptsApi;

export {
  promptsApi,
  selectors,
  useGetPromptsQuery,
  useGetPromptsByGroupQuery,
  useAddPromptMutation,
  useDeletePromptMutation,
  useUpdatePromptMutation,
};
