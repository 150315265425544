import React from "react";
import clsx from "clsx";
import { Icon, BrandIcon, type IconGlyph, type BrandIconGlyph } from "@gusarov-studio/rubik-ui";
import styles from "./NavigationActionList.module.scss";
import type { ListItem } from "../../types";

interface NavigationActionListProps {
  onClick: (event: React.MouseEvent, item: ListItem) => void;
  onDoubleClick: (event: React.MouseEvent, item: ListItem) => void;
  items: ListItem[];
  selectedItems: ListItem[];
}

function NavigationActionList(props: NavigationActionListProps) {
  const { onClick, onDoubleClick, items, selectedItems } = props;

  return (
    <div className={styles["lNavigationActionList"]}>
      {items.map((item) => (
        <a
          className={clsx(styles["lNavigationActionList__listItem"], {
            [styles["lNavigationActionList__listItemSelected"]]: selectedItems.includes(item),
          })}
          onClick={(event) => {
            event.preventDefault();
            onClick(event, item);
          }}
          onDoubleClick={(event) => onDoubleClick(event, item)}
          key={item.id}
          href="#"
        >
          {item.glyph && (
            <Icon
              size={16}
              glyph={item.glyph as IconGlyph}
            />
          )}
          {item.brand && (
            <BrandIcon
              size={16}
              glyph={item.brand as BrandIconGlyph}
            />
          )}
          <span className={styles["lNavigationActionList__listItemText"]}>{item.name}</span>
        </a>
      ))}
    </div>
  );
}

export { NavigationActionList };
