import locales from "./constants/locales";
import i18n from "./helpers/i18n";
import { localizationSlice, selectors, actions } from "./slices/localizationSlice";
import useTranslator from "./hooks/useTranslator";
import { LocalizationProvider } from "./provider/Localization";

const localization = {
  selectors,
  actions,
  locales,
};

export { localization, localizationSlice, i18n, LocalizationProvider, useTranslator };
