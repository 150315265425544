import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { Project } from "../../../types/Project";
import __ls from "../../../__localStore";

const projectsApi = createApi({
  reducerPath: "api.Projects",
  baseQuery: fetchBaseQuery({ baseUrl: "/static/mock-api" }),
  endpoints: (build) => ({
    getProjects: build.query<Project, void>({
      query: () => "/projects.json?getProjects",
      // @ts-expect-error test-purpose
      transformResponse: __ls.createOnGet("__projects"),
    }),

    getProject: build.query<Project | null, string>({
      query: (projectId) => `/projects.json?getProject=${encodeURIComponent(projectId)}`,
      transformResponse: (rawResult, meta, arg) => {
        const targetProject =
          (rawResult as Project[]).find((project) => project.id === arg) || null;

        return Promise.resolve(targetProject);
      },
    }),
  }),
});

const { useGetProjectsQuery, useGetProjectQuery } = projectsApi;

export { projectsApi, useGetProjectsQuery, useGetProjectQuery };
