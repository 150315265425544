import React, { useCallback } from "react";
import { Text, Button, Box, Stack, InputText } from "@gusarov-studio/rubik-ui";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { type Participant } from "../types";
import { useAuth } from "../../auth";
import { useAddParticipantMutation } from "../api/onboardingApi";
import { CenteredColumnLayout } from "../../../common/components/Layout";
import { routesMap } from "../../../router/routesMap";
import { i18n } from "../../localization";

function OnboardingFormScreen() {
  const { user } = useAuth();
  const { email } = user!;
  const [addParticipant, { isLoading }] = useAddParticipantMutation();
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    clearErrors,
    handleSubmit,
  } = useForm<Participant>({
    reValidateMode: "onBlur",
    defaultValues: {
      email: email!,
      firstName: "",
      lastName: "",
      companyName: "",
      role: "",
    },
  });

  const onSubmit = useCallback(
    (data: Participant) => {
      const { firstName, lastName, companyName, role } = data;

      addParticipant({
        ...data,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        companyName: companyName.trim(),
        role: role.trim(),
      }).then(() => {
        navigate(
          routesMap.onboardingScheduling.url({ name: `${firstName.trim()} ${lastName.trim()}` }),
        );
      });
    },
    [addParticipant, navigate],
  );

  return (
    <CenteredColumnLayout
      columnSize="422px"
      termsOfUse={{
        title: i18n.t("resources.termsOfUse"),
        href: "#",
      }}
      privacyPolicy={{
        title: i18n.t("resources.privacyPolicy"),
        href: "#",
      }}
      copyright="© 2024 Lisapet.AI"
    >
      <Text
        style={{ textAlign: "center" }}
        block
        size="display-md"
        weight="semibold"
      >
        Tell Us About Yourself
      </Text>

      <Box marginTop="spacing-4xl">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap="3xl">
            <Stack gap="xs">
              <Text
                size="regular-md"
                as="label"
                htmlFor="firstName"
              >
                First name
              </Text>

              <InputText
                {...register("firstName", { required: true })}
                id="firstName"
                placeholder="Enter your first name"
                invalid={Boolean(errors.firstName)}
                onInput={() => {
                  if (errors.firstName) clearErrors("firstName");
                }}
              />

              {errors.firstName && (
                <Text
                  size="regular-sm"
                  appearance="error"
                >
                  This field is required
                </Text>
              )}
            </Stack>

            <Stack gap="xs">
              <Text
                size="regular-md"
                as="label"
                htmlFor="lastName"
              >
                Last name
              </Text>

              <InputText
                {...register("lastName", { required: true })}
                id="lastName"
                placeholder="Enter your last name"
                invalid={Boolean(errors.lastName)}
                onInput={() => {
                  if (errors.lastName) clearErrors("lastName");
                }}
              />

              {errors.lastName && (
                <Text
                  size="regular-sm"
                  appearance="error"
                >
                  This field is required
                </Text>
              )}
            </Stack>

            <Stack gap="xs">
              <Text
                size="regular-md"
                as="label"
                htmlFor="company"
              >
                Company name
              </Text>

              <InputText
                {...register("companyName", { required: true })}
                id="companyName"
                placeholder="Enter your company name"
                invalid={Boolean(errors.companyName)}
                onInput={() => {
                  if (errors.companyName) clearErrors("companyName");
                }}
              />

              {errors.companyName && (
                <Text
                  size="regular-sm"
                  appearance="error"
                >
                  This field is required
                </Text>
              )}
            </Stack>

            <Stack gap="xs">
              <Text
                size="regular-md"
                as="label"
                htmlFor="role"
              >
                Position
              </Text>

              <InputText
                {...register("role", { required: true })}
                id="role"
                placeholder="Enter your role in the company"
                invalid={Boolean(errors.role)}
                onInput={() => {
                  if (errors.role) clearErrors("role");
                }}
              />

              {errors.role && (
                <Text
                  size="regular-sm"
                  appearance="error"
                >
                  This field is required
                </Text>
              )}
            </Stack>
          </Stack>

          <Box marginTop="spacing-5xl">
            <Button
              type="submit"
              disabled={isLoading}
              shape="pill"
              fullWidth
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </CenteredColumnLayout>
  );
}

export { OnboardingFormScreen };
