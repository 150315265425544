function getModelIcon(llvmName: string): string {
  switch (llvmName) {
    case "gemini":
      return "gemini";
    case "openai":
      return "chat-gpt-3";
    default:
      return llvmName;
  }
}

export { getModelIcon };
