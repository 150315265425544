import {
  getAuth,
  createUserWithEmailAndPassword as createUserWithEmailAndPasswordFirebase,
} from "firebase/auth";
import firebaseApp from "@lisapet/firebase";
import { trackUserCreation } from "../../analytics/gtm";

const auth = getAuth(firebaseApp);

function createUserWithEmailAndPassword(email: string, password: string) {
  return createUserWithEmailAndPasswordFirebase(auth, email, password).then(trackUserCreation);
}

export { createUserWithEmailAndPassword };
