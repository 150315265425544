// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l-model-settings__input-value--uHxyV{float:right}`, "",{"version":3,"sources":["webpack://./src/modules/editor/components/ModelSettings/ModelSettings.module.scss"],"names":[],"mappings":"AACE,sCACE,WAAA","sourcesContent":[".l-model-settings {\n  &__input-value {\n    float: right;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"l-model-settings__input-value": `l-model-settings__input-value--uHxyV`,
	"lModelSettings__inputValue": `l-model-settings__input-value--uHxyV`
};
export default ___CSS_LOADER_EXPORT___;
