import React, { useState, useRef, useEffect } from "react";
import { Text } from "@gusarov-studio/rubik-ui";

interface AvatarProps {
  src?: string | null;
  fallback: string;
  altText?: string;
}

// TODO: Remove and replace with avatar from design system
function Avatar(props: AvatarProps) {
  const { src, fallback, altText } = props;
  const imgRef = useRef<HTMLImageElement>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const onImageLoaded = () => {
      setIsLoaded(true);
    };
    const node = imgRef.current;

    node?.addEventListener("load", onImageLoaded, { once: true });

    return () => {
      node?.removeEventListener("load", onImageLoaded);
    };
  }, []);

  return (
    <>
      {src && (
        <img
          ref={imgRef}
          src={src}
          alt={altText}
          hidden={!isLoaded}
        />
      )}
      {!isLoaded && <Text size="regular-sm">{fallback}</Text>}
    </>
  );
}

export { Avatar };
