import config from "@lisapet/config";
import axios from "axios";
import type { ChatCompletionRequest } from "../../types/ChatCompletion";

const chatCompletionURL = config.get("functions.chatCompletion");

const createChatCompletion = (params: ChatCompletionRequest) => {
  return axios.post(chatCompletionURL, params);
};

export default createChatCompletion;
