import React from "react";
import clsx from "clsx";
import { Icon } from "@gusarov-studio/rubik-ui";
import styles from "./TopBarItem.module.scss";

interface TopBarItem extends React.PropsWithChildren {
  className?: string;
  active?: boolean;
  onClick?: React.MouseEventHandler;
  onClose?: React.MouseEventHandler;
}

function TopBarItem(props: TopBarItem) {
  const { active = false, onClose, className, onClick, children, ...otherProps } = props;

  const classes = clsx(styles["lTopBarItem"], className, {
    [styles["lTopBarItemActive"]]: active,
  });

  return (
    <div
      className={classes}
      onClick={onClick}
      {...otherProps}
    >
      <div className={styles["lTopBarItem__content"]}>{children}</div>

      {onClose && (
        <button
          className={styles["lTopBarItem__closeBtn"]}
          onClick={onClose}
        >
          <Icon
            glyph="x"
            size={18}
          />
        </button>
      )}
    </div>
  );
}

export { TopBarItem };
