// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l-response--orho5{overflow:hidden}.l-response__item-header--LQi1Y{border-top-left-radius:6px;border-top-right-radius:6px;background-color:var(--bg-primary);padding:6px 16px;display:flex;justify-content:space-between;align-items:center}.l-response__item-body--K4c6z{background-color:var(--bg-tertiary-solid);padding:12px 16px;border-bottom-left-radius:6px;border-bottom-right-radius:6px;position:relative}.l-response__stats--yghic{margin-top:16px;display:flex;flex-wrap:wrap}.l-response__stat--CxsW7{display:inline-flex;align-items:center;gap:6px}.l-response__stat--CxsW7:not(:last-child){margin-right:32px}`, "",{"version":3,"sources":["webpack://./src/modules/editor/components/ExecutionResponse/ExecutionResponse.module.scss"],"names":[],"mappings":"AAAA,mBACE,eAAA,CAKA,gCACE,0BAAA,CACA,2BAAA,CACA,kCAAA,CACA,gBAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CAGF,8BACE,yCAAA,CACA,iBAAA,CACA,6BAAA,CACA,8BAAA,CACA,iBAAA,CAGF,0BACE,eAAA,CACA,YAAA,CACA,cAAA,CAGF,yBACE,mBAAA,CACA,kBAAA,CACA,OAAA,CAEA,0CACE,iBAAA","sourcesContent":[".l-response {\n  overflow: hidden;\n\n  $responseVerticalPadding: 12px;\n  $responseHorizontalPadding: 16px;\n\n  &__item-header {\n    border-top-left-radius: 6px;\n    border-top-right-radius: 6px;\n    background-color: var(--bg-primary);\n    padding: 6px 16px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n\n  &__item-body {\n    background-color: var(--bg-tertiary-solid);\n    padding: 12px 16px;\n    border-bottom-left-radius: 6px;\n    border-bottom-right-radius: 6px;\n    position: relative;\n  }\n\n  &__stats {\n    margin-top: 16px;\n    display: flex;\n    flex-wrap: wrap;\n  }\n\n  &__stat {\n    display: inline-flex;\n    align-items: center;\n    gap: 6px;\n\n    &:not(:last-child) {\n      margin-right: 32px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"l-response": `l-response--orho5`,
	"lResponse": `l-response--orho5`,
	"l-response__item-header": `l-response__item-header--LQi1Y`,
	"lResponse__itemHeader": `l-response__item-header--LQi1Y`,
	"l-response__item-body": `l-response__item-body--K4c6z`,
	"lResponse__itemBody": `l-response__item-body--K4c6z`,
	"l-response__stats": `l-response__stats--yghic`,
	"lResponse__stats": `l-response__stats--yghic`,
	"l-response__stat": `l-response__stat--CxsW7`,
	"lResponse__stat": `l-response__stat--CxsW7`
};
export default ___CSS_LOADER_EXPORT___;
