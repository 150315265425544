// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l-dialog-layout--RTCuh{position:fixed;top:0;bottom:0;left:0;right:0}`, "",{"version":3,"sources":["webpack://./src/modules/dialogs/components/DialogLayout/DialogLayout.module.scss"],"names":[],"mappings":"AAAA,wBACE,cAAA,CACA,KAAA,CACA,QAAA,CACA,MAAA,CACA,OAAA","sourcesContent":[".l-dialog-layout {\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"l-dialog-layout": `l-dialog-layout--RTCuh`,
	"lDialogLayout": `l-dialog-layout--RTCuh`
};
export default ___CSS_LOADER_EXPORT___;
