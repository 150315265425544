import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import {
  prompts,
  useGetPromptsQuery,
  useUpdatePromptMutation,
  useDeletePromptMutation,
} from "../../prompts";
import { useAppSelector } from "../../../store/hooks";
import { useNavigate } from "react-router-dom";
import { useGetPromptGroupsQuery } from "../../promptGroups";

import { routesMap } from "../../../router/routesMap";
import { PromptPlayground } from "../components/PromptPlayground/";
import type { Log, PlaygroundForm, ResponseLog } from "../types";
import { dialogs } from "../../dialogs";
import { ExecutionLogs } from "../components/ExecutionLogs";
import { logTypes } from "../constants/logTypes";

interface PromptEditorProps {
  id: string;
}

function PromptEditorContainer(props: PromptEditorProps) {
  const { id } = props;
  const { isLoading } = useGetPromptsQuery();
  const targetPrompt = useAppSelector((state) => prompts.selectors.getPromptById(state, id));
  const [updatePrompt, { isLoading: isUpdateProcessing }] = useUpdatePromptMutation();
  const [deletePrompt, { isLoading: isDeleteProcessing }] = useDeletePromptMutation();
  const { data: promptGroups, isLoading: isGroupsQueryLoading } = useGetPromptGroupsQuery();
  const navigate = useNavigate();
  const messages = [
    {
      type: "system",
      value: "System messages uses {{systemName}}",
      id: "dfsf",
    },
    {
      type: "user",
      value: "Test message {{testVariable}} and {{testVariable_2}}",
      id: "fdfd",
    },
  ];

  const [logs, setLogs] = useState<Log[]>(
    [
      {
        time: "1:12:04 PM",
        type: logTypes.INFO,
        data: {
          message: "Execution started",
        },
      },
      {
        time: "1:12:42 PM",
        type: logTypes.RESULT,
        data: {
          responseTime: "30s",
          cost: 0.0001,
          tokens: 300,
          message: JSON.stringify(
            {
              reason:
                "The information provided clearly indicates that the website topic is a car rental business.",
              result: true,
              topic: 321,
            },
            null,
            2,
          ),
          llvm: "chat-gpt-3",
        },
      },
      {
        time: "1:18:47 PM",
        type: logTypes.INFO,
        data: {
          message: "Execution completed",
        },
      },
    ].reverse(),
  );

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    (data: PlaygroundForm) => {
      dispatch(
        dialogs.actions.open({
          dialogName: dialogs.dialogNames.TEST,
          props: {
            data,
            onClose: () => dispatch(dialogs.actions.close()),
          },
        }),
      );

      const { messages } = data;
      const value = messages[messages.length - 1].value;

      setLogs((currentLogs) => [
        {
          time: "1:12:04 PM",
          type: logTypes.INFO,
          data: {
            message: "Execution started",
          },
        },
        {
          time: "1:12:42 PM",
          type: logTypes.RESULT,
          data: {
            responseTime: "30s",
            cost: 0.0001,
            tokens: 300,
            message: value,
            llvm: "chat-gpt-3",
          },
        },
        {
          time: "1:18:47 PM",
          type: logTypes.INFO,
          data: {
            message: "Execution completed",
          },
        },
        ...currentLogs,
      ]);
    },
    [dispatch],
  );
  const [areLogsVisible, setLogsVisibility] = useState(false);

  const isDisabled = isUpdateProcessing || isDeleteProcessing || isGroupsQueryLoading || false;

  const onEdit = <T,>(data: T) => {
    updatePrompt({
      id,
      ...data,
    });
  };

  const copyToClipboard = (log: ResponseLog) => {
    navigator.clipboard.writeText(log.data.message).then(
      () => {
        console.log("Text copied to clipboard");
      },
      (err) => {
        console.error("Failed to copy text: ", err);
      },
    );
  };

  const onDelete = () => {
    deletePrompt({
      promptId: id,
    }).then(() => {
      navigate(routesMap.newFile.url());
    });
  };

  if (isLoading || !targetPrompt) {
    return <div>Loading....</div>;
  }

  return (
    <>
      <PromptPlayground
        prompt={targetPrompt}
        promptGroups={promptGroups || []}
        onEdit={onEdit}
        onDelete={onDelete}
        onSubmit={onSubmit}
        onLogClick={() => setLogsVisibility((areVisible) => !areVisible)}
        messages={messages}
        disabled={isDisabled}
      />

      {areLogsVisible && (
        <ExecutionLogs
          title="Execution logs"
          onClose={() => setLogsVisibility(false)}
          labels={{
            title: "Result",
            buttonText: "Copy",
            tokens: "Total tokens",
            cost: "Total cost",
            responseTime: "Response time",
          }}
          onResponseClick={copyToClipboard}
          logs={logs}
        />
      )}
    </>
  );
}

export { PromptEditorContainer };
