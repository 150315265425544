import config from "@lisapet/config";
import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";

const firebaseConfig = config.get("firebase");
const firebaseApp = initializeApp(firebaseConfig);

switch (config.get("env")) {
  case "local": {
    const auth = getAuth();
    const authConfig = config.get("emulators.auth");
    connectAuthEmulator(auth, authConfig);
    break;
  }
}

export default firebaseApp;
