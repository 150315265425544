import React from "react";
import { useParams } from "react-router-dom";
import { fileTypes } from "../../../common/constants/fileTypes";
import { CreatePromptContainer } from "../containers/CreatePromptContainer";
import { EditorLayout } from "../components/EditorLayout";

function CreateFileScreen() {
  const { fileType } = useParams();

  return (
    <>
      {fileType === fileTypes.PROMPT && (
        <EditorLayout>
          <CreatePromptContainer />
        </EditorLayout>
      )}
    </>
  );
}

export { CreateFileScreen };
