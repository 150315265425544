import React from "react";
import { Text, Box } from "@gusarov-studio/rubik-ui";
import { useSearchParams } from "react-router-dom";
import { CenteredColumnLayout } from "../../../common/components/Layout";
import { CalendlyWidget } from "../components/CalendlyWidget";
import { useAuth } from "../../auth";
import { i18n } from "../../localization";

function OnboardingSchedulingScreen() {
  const [searchParams] = useSearchParams();
  const { user } = useAuth();
  const { email, displayName } = user!;
  const name = searchParams.get("name") || displayName;

  return (
    <CenteredColumnLayout
      columnSize="636px"
      termsOfUse={{
        title: i18n.t("resources.termsOfUse"),
        href: "#",
      }}
      privacyPolicy={{
        title: i18n.t("resources.privacyPolicy"),
        href: "#",
      }}
      copyright="© 2024 Lisapet.AI"
    >
      <Text
        style={{ textAlign: "center", marginBottom: "8px" }}
        block
        size="regular-sm"
      >
        Thank you for requesting access to Lisapet.ai! 🙌
      </Text>

      <Text
        style={{ textAlign: "center" }}
        block
        size="display-md"
        weight="semibold"
      >
        Let's schedule a introductory call
      </Text>

      <Text
        style={{ textAlign: "center", marginTop: 16 }}
        block
      >
        As we’re in the early invite-only stage, we’d like to discuss your needs to ensure our
        platform can truly add 10x value to your process.
      </Text>

      <Box marginTop="spacing-4xl">
        <CalendlyWidget
          email={email!}
          name={name}
        />
      </Box>
    </CenteredColumnLayout>
  );
}

export { OnboardingSchedulingScreen };
