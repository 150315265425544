import { actions, selectors, dialogsSlice } from "./slices/dialogsSlice";
import { dialogNames } from "./dialogNames";
import { initialize } from "./initialize";

initialize();

const dialogs = {
  actions,
  selectors,
  dialogNames,
};

export { dialogs, dialogsSlice };
