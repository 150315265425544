import React, { useContext } from "react";
import { Text, Button, ThemesContext, Icon } from "@gusarov-studio/rubik-ui";
import { clsx } from "clsx";
import styles from "./ExecutionResponse.module.scss";
import { TextFragment } from "../TextFragment";
import type { ResponseLog } from "../../types";

interface ExecutionResponseProps {
  onClick?: React.MouseEventHandler;
  log: ResponseLog;
  labels: {
    title: string;
    buttonText: string;
    cost: string;
    responseTime: string;
    tokens: string;
  };
}

function ExecutionResponse(props: ExecutionResponseProps) {
  const { log, labels, onClick } = props;
  const { data } = log;
  const themesContext = useContext(ThemesContext);

  return (
    <div className={styles["lResponse"]}>
      <div className={clsx(styles["lResponse__itemHeader"], themesContext.themes.dark)}>
        <Text>{labels.title}</Text>

        {onClick && (
          <Button
            variant="text-primary"
            onClick={onClick}
          >
            <Icon
              glyph="clipboard"
              size="20"
              appearance="inherit"
              style={{ marginRight: "6px" }}
            />
            {labels.buttonText}
          </Button>
        )}
      </div>

      <div className={styles["lResponse__itemBody"]}>
        <TextFragment text={data.message} />
      </div>

      <div className={styles["lResponse__stats"]}>
        <div className={styles["lResponse__stat"]}>
          <Icon
            glyph="token"
            size="18"
            appearance="inherit"
          />

          <Text size="regular-xs">
            {`${labels.tokens}: `}
            <b>${data.tokens}</b>
          </Text>
        </div>

        <div className={styles["lResponse__stat"]}>
          <Icon
            glyph="cost"
            size="18"
            appearance="inherit"
          />

          <Text size="regular-xs">
            {`${labels.cost}: `}
            <b>${data.cost}</b>
          </Text>
        </div>

        <div className={styles["lResponse__stat"]}>
          <Icon
            glyph="clock"
            size="18"
            appearance="inherit"
          />

          <Text size="regular-xs">
            {`${labels.responseTime}: `}
            <b>${data.responseTime}</b>
          </Text>
        </div>
      </div>
    </div>
  );
}

export { ExecutionResponse };
