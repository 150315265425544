import type { PayloadAction } from "@reduxjs/toolkit";
import { createAppSlice } from "../../../store/createAppSlice";

interface DialogsState {
  activeDialog: string | null;
  props: Record<string, unknown>;
  isOpen: boolean;
}

interface OpenDialogPayload {
  dialogName: string;
  props?: DialogsState["props"];
}

const initialState: DialogsState = {
  activeDialog: null,
  isOpen: false,
  props: {},
};

const dialogsSlice = createAppSlice({
  name: "dialogs",
  initialState,

  reducers: {
    open: (state, action: PayloadAction<OpenDialogPayload>) => {
      state.activeDialog = action.payload.dialogName;
      state.props = action.payload.props || {};
      state.isOpen = true;
    },

    close: (state) => {
      state.activeDialog = null;
      state.props = {};
      state.isOpen = false;
    },
  },

  selectors: {
    getIsOpen: (state) => state.isOpen,
    getActiveDialog: (state) => state.activeDialog,
    getProps: (state) => state.props,
    getDialogsState: (state) => state,
  },
});

const { actions, selectors } = dialogsSlice;

export { actions, selectors, dialogsSlice };
