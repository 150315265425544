import * as api from "./api";

async function __prepareLocalStorage() {
  localStorage.setItem("__promptGroups", "[]");
  localStorage.setItem("__projects", "[]");
  localStorage.setItem("__prompts", "[]");
  localStorage.setItem("__reports", "[]");

  const options = { timeout: 0.0001 };

  const project = await api.projects.create(
    {
      name: "My project",
    },
    options,
  );

  for (let i = 0; i < 5; i++) {
    const promptGroup = await api.promptGroups.create(
      {
        projectId: project.id,
        title: `promptGroup[${i}].project[${project.id}]`,
      },
      options,
    );

    for (let i = 0; i < 7; i++) {
      await api.prompts.create(
        {
          name: `prompt[${i}].${promptGroup.title}`,
          promptGroupId: promptGroup.id,
          llvm: Math.random() > 0.5 ? "openai" : "gemini",
        },
        options,
      );
    }
  }

  for (let i = 0; i < 5; i++) {
    await api.reports.create(
      {
        title: `report[${i}].project[${project.id}]`,
        projectId: project.id,
      },
      options,
    );
  }

  console.log("done");
}

export default __prepareLocalStorage;
