import React, { useCallback, useEffect, useMemo } from "react";
import { useFieldArray, useForm, type ControllerRenderProps } from "react-hook-form";
import { PromptSetup } from "../PromptSetup";
import { PromptConfiguration } from "../PromptConfiguration";
import type { NewPrompt, Prompt } from "../../../../types/Prompt";
import type { Message, PlaygroundForm, Variable } from "../../types";
import { useSupportedAiModels } from "../../hooks/useSupportedAiModels";
import { PromptMessages } from "../PromptMessages";
import { extractVariables } from "../../helpers/extractVariables";
import { messageTypes } from "../../constants/messageTypes";
import type { PromptGroup } from "../../../../types/PromptGroup";

function extractVariablesFromMessages(messages: Message[]) {
  const variables = new Set<string>();

  messages.forEach((message) => {
    const variableNames = extractVariables(message.value);

    variableNames.forEach((variableName) => {
      variables.add(variableName);
    });
  });

  return variables;
}

interface PromptPlaygroundProps {
  prompt: NewPrompt | Prompt;
  promptGroups: PromptGroup[];
  onEdit: (prompt: NewPrompt | Prompt) => void;
  onDelete?: React.MouseEventHandler;
  onSubmit: (data: PlaygroundForm) => void;
  onLogClick?: React.MouseEventHandler;
  messages: Message[];
  disabled?: boolean;
}

function PromptPlayground(props: PromptPlaygroundProps) {
  const {
    disabled = false,
    prompt,
    promptGroups,
    messages,
    onEdit,
    onDelete,
    onSubmit,
    onLogClick,
  } = props;

  const variables = useMemo(() => {
    return Array.from(extractVariablesFromMessages(messages));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const models = useSupportedAiModels();

  const { control, getValues, watch, handleSubmit } = useForm<PlaygroundForm>({
    defaultValues: {
      messages,
      variables: variables.map((variable) => ({
        name: variable,
        value: "",
      })),
      temperature: 1,
      maxLength: 100,
      topP: 1,
      presencePenalty: 0,
      frequencyPenalty: 0,
    },
  });

  const {
    fields: messageFields,
    append: addMessage,
    remove: removeMessage,
  } = useFieldArray({
    control,
    name: "messages",
  });

  const {
    fields: variableFields,
    append: addVariable,
    remove: removeVariable,
  } = useFieldArray({
    control,
    name: "variables",
  });

  const massagesValue = watch("messages");

  const updateVariables = useCallback(
    (massagesValue: Message[]) => {
      const messageValueList = Array.from(extractVariablesFromMessages(massagesValue));
      const existingVariables = getValues("variables");

      messageValueList.forEach((messageValue: string) => {
        const index = existingVariables.findIndex((variable) => variable.name === messageValue);

        if (index === -1) {
          addVariable({
            name: messageValue,
            value: "",
          });
        }
      });

      existingVariables.forEach((variable: Variable, index) => {
        if (!messageValueList.includes(variable.name)) {
          removeVariable(index);
        }
      });
    },
    [getValues, addVariable, removeVariable],
  );

  useEffect(() => {
    updateVariables(massagesValue);
  }, [massagesValue, updateVariables]);

  const onMessageTypeClick = (field: ControllerRenderProps<PlaygroundForm>) => {
    const value = field.value === messageTypes.SYSTEM ? messageTypes.USER : messageTypes.SYSTEM;

    field.onChange(value);
  };

  return (
    <>
      <PromptSetup
        onRunClick={handleSubmit(onSubmit)}
        onLogClick={onLogClick}
        prompt={prompt}
        sidebar={
          <PromptConfiguration
            control={control}
            prompt={prompt}
            onSubmit={onEdit}
            models={models}
            disabled={disabled}
            promptGroups={promptGroups}
            variables={variableFields}
            onDelete={onDelete}
          />
        }
        disabled={disabled}
      >
        <PromptMessages
          onAddMessage={() => {
            addMessage({
              type: messageTypes.USER,
              value: "",
              id: "",
            });
          }}
          messageFields={messageFields}
          control={control}
          onMessageTypeClick={onMessageTypeClick}
          onMessageRemove={(_, index) => removeMessage(index)}
          onChange={() => {
            updateVariables(getValues("messages"));
          }}
        />
      </PromptSetup>
    </>
  );
}

export { PromptPlayground };
