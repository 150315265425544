// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l-execution-log--Jh0mO{display:flex;gap:24px;align-items:stretch;padding-left:24px;padding-right:24px}.l-execution-log--type-info--xIjY7{padding-top:8px;padding-bottom:8px;background-color:var(--bg-secondary)}.l-execution-log--type-result--yYrqX{padding-top:16px;padding-bottom:16px;background-color:var(--bg-primary)}.l-execution-log__time--zK7le{flex:0 0 90px;min-width:90px}.l-execution-log__icon--EUGDU{line-height:1;flex:0 0 24px;min-width:24px}.l-execution-log__info--HrzsP{flex:1 1 100%;min-width:0}`, "",{"version":3,"sources":["webpack://./src/modules/editor/components/ExecutionLogs/ExecutionLog.module.scss"],"names":[],"mappings":"AAAA,wBAGE,YAAA,CACA,QAAA,CACA,mBAAA,CACA,iBALoB,CAMpB,kBANoB,CAQpB,mCAGE,eAFkB,CAGlB,kBAHkB,CAIlB,oCAAA,CAGF,qCAGE,gBAFkB,CAGlB,mBAHkB,CAIlB,kCAAA,CAGF,8BACE,aAAA,CACA,cAAA,CAGF,8BACE,aAAA,CACA,aAAA,CACA,cAAA,CAGF,8BACE,aAAA,CACA,WAAA","sourcesContent":[".l-execution-log {\n  $horizontalPadding: 24px;\n\n  display: flex;\n  gap: 24px;\n  align-items: stretch;\n  padding-left: $horizontalPadding;\n  padding-right: $horizontalPadding;\n\n  &--type-info {\n    $verticalPadding: 8px;\n\n    padding-top: $verticalPadding;\n    padding-bottom: $verticalPadding;\n    background-color: var(--bg-secondary);\n  }\n\n  &--type-result {\n    $verticalPadding: 16px;\n\n    padding-top: $verticalPadding;\n    padding-bottom: $verticalPadding;\n    background-color: var(--bg-primary);\n  }\n\n  &__time {\n    flex: 0 0 90px;\n    min-width: 90px;\n  }\n\n  &__icon {\n    line-height: 1;\n    flex: 0 0 24px;\n    min-width: 24px;\n  }\n\n  &__info {\n    flex: 1 1 100%;\n    min-width: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"l-execution-log": `l-execution-log--Jh0mO`,
	"lExecutionLog": `l-execution-log--Jh0mO`,
	"l-execution-log--type-info": `l-execution-log--type-info--xIjY7`,
	"lExecutionLogTypeInfo": `l-execution-log--type-info--xIjY7`,
	"l-execution-log--type-result": `l-execution-log--type-result--yYrqX`,
	"lExecutionLogTypeResult": `l-execution-log--type-result--yYrqX`,
	"l-execution-log__time": `l-execution-log__time--zK7le`,
	"lExecutionLog__time": `l-execution-log__time--zK7le`,
	"l-execution-log__icon": `l-execution-log__icon--EUGDU`,
	"lExecutionLog__icon": `l-execution-log__icon--EUGDU`,
	"l-execution-log__info": `l-execution-log__info--HrzsP`,
	"lExecutionLog__info": `l-execution-log__info--HrzsP`
};
export default ___CSS_LOADER_EXPORT___;
