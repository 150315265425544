import type { UserCredential } from "firebase/auth";

declare global {
  interface Window {
    dataLayer: { push: (event: { event: string }) => void };
  }
}

const trackUserCreation = (userCredential: UserCredential): UserCredential => {
  const { user } = userCredential;
  const { metadata } = user;
  const { creationTime, lastSignInTime } = metadata;

  if (creationTime === lastSignInTime) {
    window.dataLayer.push({ event: "auth.user_created" });
  }

  return userCredential;
};

export { trackUserCreation };
