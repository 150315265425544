import React from "react";
import styles from "./Divider.module.scss";

interface DividerProps {
  message: string;
}

function Divider(props: DividerProps) {
  const { message } = props;

  return (
    <div className={styles["lAuthDivider"]}>
      <span>{message}</span>
    </div>
  );
}

export { Divider };
