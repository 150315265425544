import { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import firebaseApp from "@lisapet/firebase";

function useAuth() {
  const auth = getAuth(firebaseApp);

  const [user, setUser] = useState(() => auth.currentUser);

  useEffect(() => {
    onAuthStateChanged(auth, (userData) => {
      setUser(userData);
    });
  }, [auth]);

  return { user };
}

export { useAuth };
