import React, { useRef } from "react";
import clsx from "clsx";
import styles from "./TopBar.module.scss";

type TopBarProps = React.PropsWithChildren<{
  className?: string;
  startContent: React.ReactNode;
}>;

function TopBar(props: TopBarProps) {
  const { className = null, children, startContent, ...otherProps } = props;
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const classes = clsx(styles["lTopBar"], className);

  const onWheel: React.WheelEventHandler = (event) => {
    event.stopPropagation();
    // make horizontal scroll on mouse wheel
    scrollContainerRef.current!.scrollLeft += event.deltaY;
  };

  return (
    <div
      className={classes}
      {...otherProps}
    >
      <div className={styles["lTopBar__fixedElements"]}>{startContent}</div>

      <div
        className={styles["lTopBar__scrollingElements"]}
        ref={scrollContainerRef}
        onWheel={onWheel}
      >
        {children}
      </div>
    </div>
  );
}

export { TopBar };
