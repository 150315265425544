import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Text } from "@gusarov-studio/rubik-ui";
import { signOut } from "../requests/signOut";
import { routesMap } from "../../../router/routesMap";
import { CenteredColumnLayout } from "../../../common/components/Layout";
import { useTranslator } from "../../localization";

function LogoutScreen() {
  const navigate = useNavigate();
  const i18n = useTranslator();

  useEffect(() => {
    signOut()
      .then(() => navigate(routesMap.login.url()))
      .catch((e) => console.log(e));
  }, [navigate]);

  return (
    <CenteredColumnLayout
      termsOfUse={{
        title: i18n.t("resources.termsOfUse"),
        href: "#",
      }}
      privacyPolicy={{
        title: i18n.t("resources.privacyPolicy"),
        href: "#",
      }}
      copyright={i18n.t("copyright")}
    >
      <Text
        style={{ textAlign: "center" }}
        block
        size="display-md"
        weight="semibold"
      >
        {i18n.t("auth.loggedOut")}
      </Text>
    </CenteredColumnLayout>
  );
}

export { LogoutScreen };
