import type { Prompt } from "../../types/Prompt";

function create(
  verificationPromptData: Omit<Prompt, "id">,
  options: { timeout?: number } = {},
): Promise<Prompt> {
  return new Promise((res) => {
    setTimeout(() => {
      const verificationPrompts = localStorage.getItem("__prompts");
      const data = verificationPrompts ? JSON.parse(verificationPrompts) : [];

      const item: Prompt = {
        ...verificationPromptData,
        id: `prompt-${Date.now()}-${Math.random()}`,
      };

      data.push(item);
      localStorage.setItem("__prompts", JSON.stringify(data));

      res(item);
    }, options.timeout || 1000);
  });
}

export default create;
