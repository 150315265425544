import React from "react";
import styles from "./NavigationContent.module.scss";

function NavigationContent(props: React.PropsWithChildren) {
  const { children } = props;

  return <div className={styles["lNavigationContent"]}>{children}</div>;
}

export { NavigationContent };
