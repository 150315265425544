// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l-text-fragment--CzEzs{position:relative}.l-text-fragment__hidden-text-fragment--Pdj2B,.l-text-fragment__hidden-text-fragment--Pdj2B:disabled,.l-text-fragment__hidden-text-fragment--Pdj2B:focus,.l-text-fragment__text-fragment--I8wFR,.l-text-fragment__text-fragment--I8wFR:disabled,.l-text-fragment__text-fragment--I8wFR:focus{width:100%;height:145px;font-size:14px;font-weight:400;padding:0;margin:0;background:rgba(0,0,0,0);overflow:hidden;resize:none;border:none;outline:none;font-family:"Roboto Mono",monospace}.l-text-fragment__hidden-text-fragment--Pdj2B{height:0;visibility:hidden;position:absolute;z-index:-1;top:0;left:0;right:0}`, "",{"version":3,"sources":["webpack://./src/modules/editor/components/TextFragment/TextFragment.module.scss"],"names":[],"mappings":"AAAA,wBACE,iBAAA,CAIE,6RAGE,UAAA,CACA,YAAA,CACA,cAAA,CACA,eAAA,CACA,SAAA,CACA,QAAA,CACA,wBAAA,CACA,eAAA,CACA,WAAA,CACA,WAAA,CACA,YAAA,CACA,mCAAA,CAIJ,8CACE,QAAA,CACA,iBAAA,CACA,iBAAA,CACA,UAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA","sourcesContent":[".l-text-fragment {\n  position: relative;\n\n  &__hidden-text-fragment,\n  &__text-fragment {\n    &,\n    &:disabled,\n    &:focus {\n      width: 100%;\n      height: 145px;\n      font-size: 14px;\n      font-weight: 400;\n      padding: 0;\n      margin: 0;\n      background: transparent;\n      overflow: hidden;\n      resize: none;\n      border: none;\n      outline: none;\n      font-family: \"Roboto Mono\", monospace;\n    }\n  }\n\n  &__hidden-text-fragment {\n    height: 0;\n    visibility: hidden;\n    position: absolute;\n    z-index: -1;\n    top: 0;\n    left: 0;\n    right: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"l-text-fragment": `l-text-fragment--CzEzs`,
	"lTextFragment": `l-text-fragment--CzEzs`,
	"l-text-fragment__hidden-text-fragment": `l-text-fragment__hidden-text-fragment--Pdj2B`,
	"lTextFragment__hiddenTextFragment": `l-text-fragment__hidden-text-fragment--Pdj2B`,
	"l-text-fragment__text-fragment": `l-text-fragment__text-fragment--I8wFR`,
	"lTextFragment__textFragment": `l-text-fragment__text-fragment--I8wFR`
};
export default ___CSS_LOADER_EXPORT___;
