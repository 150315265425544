import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { dialogs } from "../../dialogs";
import { useNavigate } from "react-router-dom";

function DialogExample() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      dialogs.actions.open({
        dialogName: dialogs.dialogNames.TEST,
        props: {
          onClose: () => navigate(-1),
        },
      }),
    );

    return () => {
      dispatch(dialogs.actions.close());
    };
  }, [dispatch, navigate]);

  return null;
}

export { DialogExample };
