import {
  getAuth,
  signInWithEmailAndPassword as signInWithEmailAndPasswordFirebase,
} from "firebase/auth";
import firebaseApp from "@lisapet/firebase";

const auth = getAuth(firebaseApp);

function signInWithEmailAndPassword(email: string, password: string) {
  return signInWithEmailAndPasswordFirebase(auth, email, password);
}

export { signInWithEmailAndPassword };
